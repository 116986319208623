import React, { FC } from 'react'
// mui
import { Stack, Typography, Grid, Tooltip } from '@mui/material'
// components
import { CustomAvatar } from 'src/components/custom-avatar'
// types
import { MainVideoCardProps } from './types'
// utils
import { createDateByString, fToNow } from 'src/utils/formatTime'
import { PATH_PAGE } from 'src/routes/paths'
import { useNavigate } from 'react-router-dom'
import { fViewFormat } from 'src/utils/formatNumber'
import PreviewVideo from '../preview-video/PreviewVideo'
import { IconButtonAnimate } from "../animate";
import { useParams } from "react-router";
//

const MainVideoCard: FC<MainVideoCardProps> = ({ content }) => {
	const { id, name, created_by, created_date, reviews, video_playlist_id } = content

	const navigate = useNavigate()
	const params = useParams()

	const handleWatch = () => {
		if (video_playlist_id) {
			const { id } = params
			if (id)
				navigate(PATH_PAGE.playlist.watch(id,video_playlist_id))
		}
		else
			navigate(PATH_PAGE.watch(id))
	}

	return (
		<Grid
			item
			sx={{
				width: '100%',
				height: '60%',
				cursor: 'pointer',
				'& img': {
					borderRadius: 1,
					maxHeight: 203,
					width: '100%',
				},
			}}
		>
			<span onClick={handleWatch}>
				<PreviewVideo video={content}  />
			</span>

			<Stack>
				<Stack flexDirection='row' alignItems='flex-start' gap={1} sx={{ mt: 1.5 }}>
					<IconButtonAnimate onClick={() => navigate(PATH_PAGE.channel(created_by?.id))}>
					<CustomAvatar name={created_by?.username} src={created_by?.avatar} />
					</IconButtonAnimate>
					<Stack>
						<Tooltip title={name}>
						<Typography
							sx={{
								fontWeight: 500,
								fontSize: 16,
								display: '-webkit-box',
								WebkitLineClamp: 2,
								WebkitBoxOrient: 'vertical',
								whiteSpace: 'normal',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								maxHeight: 44,
								wordBreak: 'break-word',
								mb: 0.5,
							}}
							onClick={handleWatch}
						>
							{name}
						</Typography>
						</Tooltip>
						<Tooltip title={created_by ? created_by.username : 'Unknown'}>
						<Typography
							fontSize={14}
							onClick={() => navigate(PATH_PAGE.channel(created_by?.id))}
						>
							{created_by ? created_by.username : 'Unknown'}
						</Typography>
						</Tooltip>
						<Stack flexDirection='row' alignItems='center' gap={0.5}>
							<Typography fontSize={14}>{fViewFormat(reviews)}</Typography>
							<Typography fontSize={14}>•</Typography>
							<Typography fontSize={14}>{fToNow(createDateByString(created_date))}</Typography>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</Grid>
	)
}

export default MainVideoCard
