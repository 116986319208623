import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { IVideo } from 'src/@types/video'
import MainVideoCard from 'src/components/main-video-card/MainVideoCard'
import { getTopVideo } from 'src/redux/slices/top_video'
import { useDispatch, useSelector } from 'src/redux/store'

export function RecommendVideos() {
    const dispatch = useDispatch()
	const { videos } = useSelector(state => state.top_video)

    useEffect(()=>{
        dispatch(getTopVideo())
    }, [dispatch])

	return (
		<Grid
			container
			display='grid'
			gridAutoFlow='row'
			gridTemplateColumns='repeat(auto-fill, minmax(330px, 1fr))'
			gap={1.5}
			mx={2}
			sx={{ width: 'unset' }}
		>
			{videos.map((video: IVideo) => (
				<MainVideoCard key={video.id} content={video} />
			))}
		</Grid>
	)
}
