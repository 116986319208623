import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { dispatch as dispatchStore } from '../store'
import { IStream } from 'src/@types/stream'

const initialState: {
	isLoading: boolean
	error: Error | string | null
	streams: IStream[]
} = {
	isLoading: false,
	error: null,
	streams: [],
}

const slice = createSlice({
	name: 'streamBlock',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.streams = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getStreams(state, action) {
			state.isLoading = false
			const streams = action.payload
			state.streams = streams
		},
	},
})

// Reducer
export default slice.reducer

export function getStreams() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/blocked/stream`)
			dispatch(slice.actions.getStreams(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setUnBlock(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`api/stream/public/item/${id}/unblock`)
			dispatchStore(getStreams())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
