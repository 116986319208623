import { createContext, useEffect, useReducer, useCallback } from 'react'
// utils
import axios from '../utils/axios'
import localStorageAvailable from '../utils/localStorageAvailable'
//
import { isValidToken, setSession } from './utils'
import { ActionMapType, AuthStateType, AuthUserType, JWTContextType } from './types'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
	INITIAL = 'INITIAL',
	LOGIN = 'LOGIN',
	REGISTER = 'REGISTER',
	LOGOUT = 'LOGOUT',
	REFRESH = 'REFRESH',
}

type Payload = {
	[Types.INITIAL]: {
		isAuthenticated: boolean
		user: AuthUserType
	}
	[Types.LOGIN]: {
		user: AuthUserType
	}
	[Types.REGISTER]: {
		user: AuthUserType
	}
	[Types.REFRESH]: {
		user: AuthUserType
	}
	[Types.LOGOUT]: undefined
}

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>]

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
	isInitialized: false,
	isAuthenticated: false,
	user: null,
}

const reducer = (state: AuthStateType, action: ActionsType) => {
	if (action.type === Types.INITIAL) {
		return {
			isInitialized: true,
			isAuthenticated: action.payload.isAuthenticated,
			user: action.payload.user,
		}
	}
	if (action.type === Types.LOGIN) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		}
	}
	if (action.type === Types.REGISTER) {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		}
	}
	if (action.type === Types.LOGOUT) {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		}
	}
	if (action.type === Types.REFRESH) {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		}
	}
	return state
}

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null)

// ----------------------------------------------------------------------

type AuthProviderProps = {
	children: React.ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
	const [state, dispatch] = useReducer(reducer, initialState)

	const storageAvailable = localStorageAvailable()

	const getUserInfo = async () => {
		const response = await axios.get(`api/token/userinfo/`)
		return Object.assign(response.data)
	}

	const initialize = useCallback(async () => {
		try {
			const accessToken = storageAvailable
				? localStorage.getItem('token')
					? JSON.parse(localStorage.getItem('token') ?? '')
					: ''
				: ''

			if (accessToken && isValidToken(accessToken)) {
				setSession(accessToken)

				const user = await getUserInfo()

				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: true,
						user,
					},
				})
			} else {
				dispatch({
					type: Types.INITIAL,
					payload: {
						isAuthenticated: false,
						user: null,
					},
				})
			}
		} catch (error) {
			console.error(error)
			dispatch({
				type: Types.INITIAL,
				payload: {
					isAuthenticated: false,
					user: null,
				},
			})
		}
	}, [storageAvailable])

	useEffect(() => {
		initialize()
	}, [initialize])

	// LOGIN
	const login = useCallback(async (username: string, password: string) => {
		const response = await axios.post('/api/token/auth/', {
			username,
			password,
		})

		if ('auth_2fa' in response.data && 'user_id' in response.data) return response.data.user_id
		const { access_token, refresh_token, expires, expires_in } = response.data

		setSession({
			access_token: access_token,
			refresh_token: refresh_token,
			expires: expires,
			expires_in: expires_in,
		})

		const user = await getUserInfo()

		dispatch({
			type: Types.LOGIN,
			payload: {
				user,
			},
		})
	}, [])

	// Two factor authentication
	const check2fa = useCallback(async (code: string, user_id: string) => {
		const response = await axios.post('/api/token/2fa/', { code, user_id })
		const { access_token, refresh_token, expires, expires_in } = response.data

		setSession({
			access_token: access_token,
			refresh_token: refresh_token,
			expires: expires,
			expires_in: expires_in,
		})

		const user = await getUserInfo()

		dispatch({
			type: Types.LOGIN,
			payload: {
				user,
			},
		})
	}, [])

	// REGISTER
	const register = useCallback(async (username: string, email: string, password: string) => {
		const response = await axios.post('api/account/user', {
			username,
			email,
			password,
		})
		return response.status
	}, [])

	// REFRESH
	const refresh = useCallback(async () => {
		const user = await getUserInfo()
		dispatch({
			type: Types.REFRESH,
			payload: {
				user,
			},
		})
	}, [])

	// LOGOUT
	const logout = async () => {
		try {
			await axios.get('/api/token/revoke/')

			setSession(null)
			dispatch({
				type: Types.LOGOUT,
			})
		} catch (error) {
			console.error(error)
			setSession(null)
			dispatch({
				type: Types.LOGOUT,
			})
		}
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'jwt',
				login,
				logout,
				register,
				check2fa,
				refresh,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
