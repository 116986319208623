import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IBroadcastState } from 'src/@types/stream'
import { dispatch as dispatchStore, store } from '../store'
import { ITag } from 'src/@types/tag'

const initialState: IBroadcastState = {
	isLoading: false,
	error: null,
	stream: null,
	streams: [],
	tag: null,
	tags: []
}

const slice = createSlice({
	name: 'broadcast',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.streams = []
			state.stream = null
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getBroadcastsSuccess(state, action) {
			state.isLoading = false
			const streams = action.payload
			state.streams = streams
		},

		getBroadcastSuccess(state, action) {
			state.isLoading = false
			const stream = action.payload
			state.stream = stream
		},
		setTag(state, action) {
			const tag = action.payload
			state.tag = tag
		},
		setTags(state, action) {
			const tags = action.payload
			state.tags = tags
		},
		clearFilter(state) {
			state.tags = []
			state.tag = null

		},
	},
})

// Reducer
export default slice.reducer

export function getBroadcasts() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())

		const storage = store.getState().broadcast

		let params = new URLSearchParams()
		if (storage.tags) {
			storage.tags.map((tag: ITag) => params.append('tags', tag.id))
		}
		if (storage.tag) {
			params.append('tag', storage.tag.id)
		}

		try {
			const response = await axios.get(`api/stream/public/list`, { params })
			dispatch(slice.actions.getBroadcastsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getBroadcast(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/stream/public/item/${id}`)
			dispatch(slice.actions.getBroadcastSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setBroadcastTag(tag: ITag | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setTag(tag))
		dispatchStore(getBroadcasts())
	}
}

export function setBroadcastTags(tags: ITag[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setTags(tags))
		dispatchStore(getBroadcasts())
	}
}

export function getStreamBySearch() {
	return async (dispatch: Dispatch) => {
		const storage = store.getState().search
		dispatch(slice.actions.startLoading())
		if (storage.search) {
			dispatch(slice.actions.startLoading())
			try {
				const response = await axios.get(`/api/stream/public/search/${storage.search}`)
				dispatch(slice.actions.getBroadcastsSuccess(response.data))
			} catch (error) {
				dispatch(slice.actions.hasError(error))
			}
		} else {
			dispatchStore(getBroadcasts())
		}
	}
}

export function setBlock(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`api/stream/public/item/${id}/block`)
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function clearFilter() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.clearFilter())
		dispatchStore(getBroadcasts())
	}
}