// @mui
import { Stack, Box } from '@mui/material'
// config
import { NAV } from 'src/config-global'
// utils
import { hideScrollbarX } from 'src/utils/cssStyles'
// components
import { NavSectionMini } from 'src/components/nav-section'
//
import navConfig from './config-navigation'
import { useSettingsContext } from "../../../components/settings";
import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function NavMini() {
	const { themeBackground } = useSettingsContext()

	return (
		<Box
			component='nav'
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV.W_DASHBOARD_MINI },
			}}
		>
			<Stack
				sx={{
					pb: 2,
					height: 1,
					position: 'fixed',
					backgroundColor: themeBackground?.background ? theme => alpha(theme.palette.background.paper, 0.8) : theme => theme.palette.background.paper,
					zIndex: 10,
					width: NAV.W_DASHBOARD_MINI,
					...hideScrollbarX,
				}}
			>
				<NavSectionMini data={navConfig} />
			</Stack>
		</Box>
	)
}
