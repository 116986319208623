import { memo } from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

function VkontakteIcon({ ...other }: BoxProps) {
	return (
		<Box {...other}>
			<svg viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'>
				<g fill='none' fillRule='evenodd'>
					<circle fill='#4680C2' fillRule='nonzero' cx='30' cy='30' r='30' />
					<path
						d='M49.06121 21.60996c.28897-.90818 0-1.56868-1.27972-1.56868h-4.25195c-1.07331 0-1.56869.57794-1.85765 1.19715 0 0-2.1879 5.28399-5.2427 8.71032-.99076.99075-1.44485 1.321-1.9815 1.321-.28897 0-.6605-.33025-.6605-1.23843v-8.46264c0-1.0733-.33025-1.56868-1.23844-1.56868h-6.68754c-.6605 0-1.07331.49537-1.07331.99075 0 1.03203 1.5274 1.27971 1.69253 4.1694v6.27473c0 1.36227-.24769 1.60996-.78434 1.60996-1.44484 0-4.95374-5.32527-7.05908-11.3936C18.2242 20.4541 17.8114 20 16.73808 20h-4.29324C11.20641 20 11 20.57794 11 21.19715c0 1.1146 1.44484 6.72883 6.72883 14.15943 3.5089 5.07758 8.50391 7.80214 13.00355 7.80214 2.72456 0 3.0548-.61922 3.0548-1.65125v-3.83914c0-1.23844.2477-1.44484 1.1146-1.44484.61922 0 1.7338.33025 4.25196 2.76583C42.04342 41.879 42.53879 43.2 44.14875 43.2h4.25196c1.23844 0 1.81637-.61922 1.48612-1.81637-.37153-1.19715-1.77509-2.93096-3.59146-4.99502-.99074-1.15587-2.47686-2.43559-2.93096-3.0548-.61921-.82562-.45409-1.15587 0-1.89893-.04128 0 5.16015-7.34805 5.6968-9.82492'
						fill='#FFFFFF'
					/>
				</g>
			</svg>
		</Box>
	)
}

export default memo(VkontakteIcon)
