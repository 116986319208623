import { createSlice, Dispatch } from '@reduxjs/toolkit'
import SnackbarUtils from "../../utils/snackbar";
import { IPlaylist, IPlaylistState } from 'src/@types/playlist'
import { dispatch as dispatchStore, store } from '../store'
import axios from 'src/utils/axios'

const initialState: IPlaylistState = {
	isLoading: false,
	error: null,
	items: [],
	playlists: [],
	playlist: null,
	search: "",
	rowsPerPage: 25,
	pageNumber: 1
}

const slice = createSlice({
	name: 'playlist',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getPlaylistsSuccess(state, action) {
			state.isLoading = false
			const playlists = action.payload
			state.playlists = playlists
		},

		getPlaylistItemsSuccess(state, action) {
			state.isLoading = false
			const items = action.payload
			state.items = items
		},

		getPlaylistSuccess(state, action) {
			state.isLoading = false
			const playlist = action.payload
			state.playlist = playlist
		},

		setRowsPerPage(state, action) {
			const rows = action.payload
			state.rowsPerPage = rows
		},

		setPageNumber(state, action) {
			const page = action.payload
			state.pageNumber = page
		},

		setSearch(state, action) {
			const search = action.payload
			state.search = search
		},

	},
})

// Reducer
export default slice.reducer

export function getPrivatePlaylists() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const storage = store.getState().playlist

			let params = new URLSearchParams()

			if (storage.search) {
				params.append('search', storage.search)
			}
			const response = await axios.get(`/api/playlist/private`, { params })
			dispatch(slice.actions.getPlaylistsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPlaylists() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/playlist/`)
			dispatch(slice.actions.getPlaylistsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}


export function getPlaylist(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/playlist/${id}`)
			dispatch(slice.actions.getPlaylistSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPrivatePlaylist(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/playlist/private/${id}`)
			dispatch(slice.actions.getPlaylistSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPlaylistItems() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/playlist/items`)
			dispatch(slice.actions.getPlaylistItemsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createPlaylist(playlist: IPlaylist) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`/api/playlist/`, playlist)
			return response.data
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updatePlaylist(playlist: IPlaylist) {
	const { id } = playlist
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/playlist/${id}`, playlist)
			dispatch(slice.actions.getPlaylistSuccess(response.data))
			SnackbarUtils.success('Плейлист сохранен')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function removePlaylist(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`/api/playlist/${id}/`)
			dispatchStore(getPlaylists())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function removePlaylists(ids: string[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`/api/playlist/`, { data: { ids: ids } } )
			dispatchStore(getPlaylists())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setRowsPerPage(rows: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setRowsPerPage(rows))
		dispatchStore(setPageNumber(1))
	}
}

export function setPageNumber(page: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setPageNumber(page))
		dispatchStore(getPrivatePlaylists())
	}
}

export function setSearch(search: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSearch(search))
		if (search.length > 2 || search.length === 0) dispatchStore(setPageNumber(1))
	}
}