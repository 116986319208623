import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IVideoState } from 'src/@types/video'
import { dispatch as dispatchStore, store } from '../store'
import { ITag } from 'src/@types/tag'

const initialState: IVideoState = {
	isLoading: false,
	error: null,
	video: null,
	videos: [],
	search: '',
	tag: null,
	tags: []
}

const slice = createSlice({
	name: 'video',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.videos = []
			state.video = null
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getVideosSuccess(state, action) {
			state.isLoading = false
			const videos = action.payload
			state.videos = videos
		},

		getVideoSuccess(state, action) {
			state.isLoading = false
			const video = action.payload
			state.video = video
		},

		setTag(state, action) {
			const tag = action.payload
			state.tag = tag
		},
		setTags(state, action) {
			const tags = action.payload
			state.tags = tags
		},
		clearFilter(state) {
			state.search = ''
			state.tags = []
			state.tag = null
		},
	},
})

// Reducer
export default slice.reducer

export function getPublicVideos() {
	return async (dispatch: Dispatch) => {
		const storage = store.getState().video

		let params = new URLSearchParams()
		if (storage.tag) {
			params.append('tag', storage.tag.id)
		}
		if (storage.tags) {
			storage.tags.map((tag: ITag) => params.append('tags', tag.id))
		}
		if (storage.search) {
			params.append('search', storage.search)
		}

		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/video/public/list`, { params })
			dispatch(slice.actions.getVideosSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPublicVideo(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/video/public/item/${id}`)
			dispatch(slice.actions.getVideoSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setVideoTag(tag: ITag | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setTag(tag))
		dispatchStore(getPublicVideos())
	}
}

export function setVideoTags(tags: ITag[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setTags(tags))
		dispatchStore(getPublicVideos())
	}
}

export function getPublicVideosBySearch() {
	return async (dispatch: Dispatch) => {
		const storage = store.getState().search
		dispatch(slice.actions.startLoading())
		if (storage.search) {
			dispatch(slice.actions.startLoading())
			try {
				const response = await axios.get(`/api/video/public/search/${storage.search}`)
				dispatch(slice.actions.getVideosSuccess(response.data))
			} catch (error) {
				dispatch(slice.actions.hasError(error))
			}
		} else {
			dispatchStore(getPublicVideos())
		}
	}
}

export const getEmbededVideoThunk = createAsyncThunk(
	'video/getEmbeddedVideo',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await axios.get(`/api/video/public/item/${id}`)
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export function setBlock(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`/api/video/public/item/${id}/block`)
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function clearFilter() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.clearFilter())
		dispatchStore(getPublicVideos())
	}
}
