import React, { useState } from 'react'
// @mui
import { MenuItem, Tooltip, IconButton, Typography } from '@mui/material'
// components
import MenuPopover from 'src/components/menu-popover'
import Iconify from 'src/components/iconify/Iconify'
import ModalAddVideo from 'src/components/modal-add-video/ModalAddVideo'
import ModalCreateStream from 'src/components/modal-create-stream'
import ModalAddBlog from "../../components/modal-add-blog/ModalAddBlog";

// ----------------------------------------------------------------------

export default function CreatePopover() {
	const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)
	const [openAddVideo, setOpenAddVideo] = useState<boolean>(false)
	const [openCreateStream, setOpenCreateStream] = useState<boolean>(false)
	const [openCreateBlog, setOpenCreateBlog] = useState<boolean>(false)

	const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
		setOpenPopover(event.currentTarget)
	}

	const handleClosePopover = () => {
		setOpenPopover(null)
	}

	const handleOpenModalAddVideo = () => {
		setOpenAddVideo(true)
	}

	const handleOpenCreateStream = () => {
		setOpenCreateStream(true)
	}

	const handleOpenCreateBlog = () => {
		setOpenCreateBlog(true)
	}

	return (
		<>
			<Tooltip title='Создать'>
				<IconButton size='medium' onClick={handleOpenPopover}>
					<Iconify icon='material-symbols:video-call-outline-sharp' />
				</IconButton>
			</Tooltip>

			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
				<MenuItem
					onClick={() => {
						handleClosePopover()
						handleOpenModalAddVideo()
					}}
					sx={{ m: 1 }}
				>
					<Iconify icon={'octicon:video-24'} />
					<Typography variant={'body2'}>Добавить видео</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClosePopover()
						handleOpenCreateStream()
					}}
					sx={{ m: 1 }}
				>
					<Iconify icon={'bi:broadcast'} />
					<Typography variant={'body2'}>Начать трансляцию</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClosePopover()
						handleOpenCreateBlog()
					}}
					sx={{ m: 1 }}
				>
					<Iconify icon={'bxl:blogger'} />
					<Typography variant={'body2'}>Создать блог</Typography>
				</MenuItem>
			</MenuPopover>
			<ModalAddVideo open={openAddVideo} setOpen={setOpenAddVideo} />
			<ModalCreateStream open={openCreateStream} setOpen={setOpenCreateStream} />
			<ModalAddBlog open={openCreateBlog} setOpen={setOpenCreateBlog} />

		</>
	)
}
