import { memo } from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

function FrameIcon({ ...other }: BoxProps) {
	return (
		<Box {...other}>
			<svg
				viewBox='0 0 36 36'
				xmlns='http://www.w3.org/2000/svg'
				focusable='false'
				width='100%'
				height='100%'
			>
				<g viewBox='0 0 36 36'>
					<circle cx='18' cy='18' r='17.5' stroke='#E7E7E7' fill='#F4F4F4' strokeWidth='.5' />
					<path
						d='m21.41,23.29l-0.71,-0.71l4.59,-4.58l-4.59,-4.59l0.71,-0.71l5.3,5.3l-5.3,5.29zm-6.12,-0.7l-4.58,-4.59l4.59,-4.59l-0.71,-0.7l-5.3,5.29l5.29,5.29l0.71,-0.7z'
						fill='#606060'
					/>
				</g>
			</svg>
		</Box>
	)
}

export default memo(FrameIcon)
