import numeral from 'numeral'

// ----------------------------------------------------------------------

type InputValue = string | number | null

export function fNumber(number: InputValue) {
	return numeral(number).format()
}

export function fCurrency(number: InputValue) {
	const format = number ? numeral(number).format('$0,0.00') : ''

	return result(format, '.00')
}

export function fPercent(number: InputValue) {
	const format = number ? numeral(Number(number) / 100).format('0.0%') : ''

	return result(format, '.0')
}

export function fShortenNumber(number: InputValue) {
	const format = number ? numeral(number).format('0.00a') : ''

	return result(format, '.00')
}

export function fData(number: InputValue) {
	const format = number ? numeral(number).format('0.0 b') : ''

	return result(format, '.0')
}

function result(format: string, key = '.00') {
	const isInteger = format.includes(key)

	return isInteger ? format.replace(key, '') : format
}

export function fDictFormat(n: number, text_forms: string[]) {
	const number = n
	n = Math.abs(n) % 100
	let n1 = n % 10
	if (n > 10 && n < 20) {
		return `${fNumber(number)} ${text_forms[2]}`
	}
	if (n1 > 1 && n1 < 5) {
		return `${fNumber(number)} ${text_forms[1]}`
	}
	if (n1 === 1) {
		return `${fNumber(number)} ${text_forms[0]}`
	}
	return `${fNumber(number)} ${text_forms[2]}`
}

export function fViewFormat(n:number) {
	return fDictFormat(n, ['просмотр', 'просмотра', 'просмотров'])
}

export function fCommentFormat(n:number) {
	return fDictFormat(n, ['комментарий', 'комментария', 'комментариев'])
}