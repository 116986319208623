import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'

const initialState: {
	isLoading: boolean
	error: Error | string | null
	count: number
	vote: 'IGNORE' | 'LIKE' | 'DISLIKE'
} = {
	isLoading: false,
	error: null,
	count: 0,
	vote: 'IGNORE',
}

const slice = createSlice({
	name: 'vote',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getVotes(state, action) {
			state.isLoading = false
			const { count, vote } = action.payload
			state.count = count
			state.vote = vote
		},
	},
})

// Reducer
export default slice.reducer

export function getVotes(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/video/public/item/${id}/votes`)
			dispatch(slice.actions.getVotes(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateVote(id: string, data: { vote: string }) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`/api/video/public/item/${id}/votes`, data)
			dispatch(slice.actions.getVotes(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
