import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Collapse(theme: Theme) {
    const isLight = theme.palette.mode === 'light';

    return {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.edit-form': {
                        backgroundColor: isLight ? alpha(theme.palette.grey[200], 0.6) : alpha(theme.palette.grey[800], 0.5),
                        '&.nav-collapse': {
                            backgroundColor: 'transparent',
                        }
                    }
                },
            },
        },
    };
}
