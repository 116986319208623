import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IVideo } from 'src/@types/video'
import { dispatch as dispatchStore } from '../store'

const initialState: {
	isLoading: boolean
	error: Error | string | null
	videos: IVideo[]
} = {
	isLoading: false,
	error: null,
	videos: [],
}

const slice = createSlice({
	name: 'videoBlock',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.videos = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getVideosSuccess(state, action) {
			state.isLoading = false
			const videos = action.payload
			state.videos = videos
		},
	},
})

// Reducer
export default slice.reducer

export function getVideos() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/blocked/video`)
			dispatch(slice.actions.getVideosSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setUnBlock(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`/api/video/public/item/${id}/unblock`)
			dispatchStore(getVideos())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
