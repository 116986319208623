import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IUploadVideoState, IVideo } from 'src/@types/video'
import axios from 'src/utils/axios'
import { serialize } from 'object-to-formdata'
import SnackbarUtils from 'src/utils/snackbar'

const initialState: IUploadVideoState = {
	error: null,
	isLoading: false,
	isUpload: false,
	video: null,
	loaded: 0,
	total: 0,
}

const slice = createSlice({
	name: 'upload',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// START UPLOADING
		startUploading(state) {
			state.isUpload = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.isUpload = false
			state.error = action.payload
		},

		startUpload(state) {
			state.isUpload = true
			state.loaded = 0
			state.total = 0
			state.error = null
		},

		setCurrentUpload(state, action) {
			const { loaded, total } = action.payload
			state.loaded = loaded
			state.total = total
		},

		stopUpload(state) {
			state.isUpload = false
			state.loaded = 0
			state.total = 0
		},

		uploadVideoSuccess(state, action) {
			state.isLoading = false
			state.isUpload = false
			const video = action.payload
			state.video = video
		},
	},
})

// Reducer
export default slice.reducer

export function updateVideo(video: IVideo) {
	return async (dispatch: Dispatch) => {
		const { id } = video
		try {
			dispatch(slice.actions.startLoading())
			const response = await axios.put(`api/video/private/item/${id}`, video)
			dispatch(slice.actions.uploadVideoSuccess(response.data))
			SnackbarUtils.success('Видео обновлено')
		} catch (err) {
			console.log(err)
			SnackbarUtils.error('Ошибка при обновлении видео')
			dispatch(slice.actions.stopUpload())
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function uploadVideo(file: File) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(slice.actions.startUploading())
			const formData = serialize({
				file: file,
				name: file?.name?.split('.').slice(0, -1).join('.'),
			})
			const response = await axios.post(`api/video/private/item`, formData, {
				onUploadProgress({ loaded, total }) {
					dispatch(slice.actions.setCurrentUpload({ loaded, total }))
				},
			})
			dispatch(slice.actions.uploadVideoSuccess(response.data))
			dispatch(slice.actions.stopUpload())
			SnackbarUtils.success('Видео загружено')
		} catch (err) {
			console.log(err)
			SnackbarUtils.error('Ошибка при загрузке видео')
			dispatch(slice.actions.stopUpload())
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function cleanUpload() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.uploadVideoSuccess(null))
		dispatch(slice.actions.stopUpload())
	}
}
