import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import { IGroup, IUser } from 'src/@types/account'
import axios from 'src/utils/axios'
import SnackbarUtils from 'src/utils/snackbar'
import _ from 'lodash'

export type IAccountState = {
	isLoading: boolean
	error: Error | string | null
	account: IUser | null
	groups: IGroup[]
}

const initialState: IAccountState = {
	isLoading: false,
	error: null,
	account: null,
	groups: [],
}

const slice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.account = null
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getAccount(state, action) {
			state.isLoading = false
			const account = action.payload
			state.account = account
		},
		getGroups(state, action) {
			state.isLoading = false
			const groups = action.payload
			state.groups = groups
		},
	},
})

// Reducer
export default slice.reducer

export function getAccount() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/account`)
			dispatch(slice.actions.getAccount(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateAccount(data: Partial<IUser>) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const logo = data.avatar
			let response = await axios.put(`api/account/user/${data.id}`, _.omit(data, ['avatar']))
			if (typeof logo === 'object' && logo) {
				const formData = new FormData()
				formData.append('avatar', logo)
				response = await axios.post(`api/account/${data.id}`, formData)
			}
			dispatch(slice.actions.getAccount(response.data))
			SnackbarUtils.success('Аккаунт обновлен')
		} catch (error) {
			SnackbarUtils.error('Ошибка при обноление данных')
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function newPassword(id: string, data: { old: string; new: string }) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			let response = await axios.put(`api/account/${id}`, data)
			dispatch(slice.actions.getAccount(response.data))
			SnackbarUtils.success('Новый пароль установлен')
		} catch (error) {
			SnackbarUtils.error('Ошибка обновления пароля')
			dispatch(slice.actions.hasError(error))
		}
	}
}

export const setGroupAccountThunk = createAsyncThunk(
	'setGroupAccount',
	async ({ groupId, id }: { groupId: string | null; id: string }, { rejectWithValue }) => {
		try {
			const response = await axios.put(`api/account/user/${id}`, { active_group_id_id: groupId })
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export function getGroups() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/account/groups`)
			dispatch(slice.actions.getGroups(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createGroup(data: { name: string; is_global: boolean }) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`api/account/groups`, data)
			dispatch(slice.actions.getGroups(response.data))
			SnackbarUtils.success('Новая группа добавлена')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			SnackbarUtils.error('Ошибка добавление группы')
		}
	}
}

export function addUserToGroup(groupId: string, data: { user: string; priviledge: number }) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`api/account/groups/${groupId}/user_add`, data)
			dispatch(slice.actions.getGroups(response.data))
			SnackbarUtils.success('Пользователь добавлен')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			SnackbarUtils.error('Ошибка добавление пользователя')
		}
	}
}

export function removeUserToGroup(groupId: string, data: { user: string; priviledge: number }) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`api/account/groups/${groupId}/user_remove`, data)
			dispatch(slice.actions.getGroups(response.data))
			SnackbarUtils.success('Пользователь удален')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			SnackbarUtils.error('Ошибка удаления')
		}
	}
}

export function updateGroup(data: IGroup) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`api/account/groups/${data.id}`, _.omit(data, ['avatar']))
			dispatch(slice.actions.getGroups(response.data))
			SnackbarUtils.success('Группа обновлена')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			SnackbarUtils.error('Ошибка обновления группы')
		}
	}
}
export function updateAvatarGroup(id: string, avatar: string | File) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const formData = new FormData()
			formData.append('avatar', avatar)
			const response = await axios.post(`api/account/groups/${id}`, formData)
			dispatch(slice.actions.getGroups(response.data))
			SnackbarUtils.success('Группа обновлена')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			SnackbarUtils.error('Ошибка обновления группы')
		}
	}
}

export function removeGroup(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.delete(`api/account/groups/${id}`)
			dispatch(slice.actions.getGroups(response.data))
			SnackbarUtils.success('Группа удалена')
		} catch (error) {
			dispatch(slice.actions.hasError(error))
			SnackbarUtils.error('Ошибка удаления')
		}
	}
}

export const getUsersAllThunk = createAsyncThunk(
	'getUsersAll',
	async (controller: AbortController, { rejectWithValue }) => {
		try {
			const response = await axios.get(`api/account/user`, {
				signal: controller && controller.signal,
			})
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
