import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Collapse(theme: Theme) {
    const isLight = theme.palette.mode === 'light';

    return {
        MuiCollapse: {
            styleOverrides: {
                root: {
                    backgroundColor: isLight ? alpha(theme.palette.grey[200], 0.9) : alpha(theme.palette.grey[200], 0.3),
                    '&.nav-collapse': {
                        backgroundColor: 'transparent',
                    }
                },
            },
        },
    };
}
