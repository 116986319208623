import React, { useState } from 'react'
import { useDispatch, useSelector } from 'src/redux/store'
import { Box, Button, Collapse, Stack, Tooltip, Typography } from '@mui/material'
import { CustomAvatar } from 'src/components/custom-avatar'
import Iconify from 'src/components/iconify'
import { createDateByString, fToNow } from 'src/utils/formatTime'
import { fViewFormat } from 'src/utils/formatNumber'
import Comments from './comments/Comments'
import { RecommendVideos } from './RecommendVideos'
import CustomReactPlayer from './ReactPlayer'
import ConfirmDialog from 'src/components/confirm-dialog'
import ModalShare from 'src/components/modalShare/ModalShare'
import Markdown from 'src/components/markdown'
import Vote from './Vote'
import Ticker from 'react-ticker'
import TextTicker from './TextTicker'
import { alpha } from "@mui/material/styles";
import { setBlock } from 'src/redux/slices/video'
import Playlist from "../playlist/PlaylistForm";
import { PATH_PAGE } from "src/routes/paths";
import { IconButtonAnimate } from "src/components/animate";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
type Props = {
	isItemPlaylist?: boolean
}
export default function WatchVideoForm({ isItemPlaylist = false } : Props) {
	const { video } = useSelector(state => state.video)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [open, setOpen] = useState<boolean>(false)

	const [openConfirm, setOpenConfirm] = useState(false)

	const handleCloseConfirm = () => {
		setOpenConfirm(false)
	}

	const [checked, setChecked] = useState<boolean>(false)

	const handleChange = () => {
		setChecked((prev: boolean) => !prev)
	}

	const handleClickOpen = () => {
		setOpen(true)
	}

	return (
		<Stack direction={'column'} sx={{ pt: 2 }}>
			<Stack direction={'row'} spacing={3}>
				<Stack
					sx={{ minWidth: 300, width: '70%', maxHeight: '40%', position: 'relative' }}
					spacing={2}
					direction={'column'}
				>
					<Stack width={'100%'} sx={{ position: 'absolute', backgroundColor: theme => alpha(theme.palette.background.paper, 0.5) }}>
						<Ticker mode='await' speed={10}>
							{() => <TextTicker  />}
						</Ticker>
					</Stack>
					<CustomReactPlayer video={video} />
					<Typography variant='h5'>{video?.name}</Typography>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent={'space-between'}
						gap={1}
						flexWrap={'wrap'}
					>
						<Stack direction='row' alignItems='center' spacing={1}>
							<IconButtonAnimate onClick={() => navigate(PATH_PAGE.channel(video?.created_by?.id  ? video?.created_by?.id : ''))}>
							<CustomAvatar name={video?.created_by?.username} src={video?.created_by?.avatar} />
							</IconButtonAnimate>
							<Box sx={{ pr: 2 }}>
								<Tooltip onClick={() => navigate(PATH_PAGE.channel(video?.created_by?.id ? video?.created_by?.id : ''))}
										 title={video?.created_by?.username}>
									<Typography sx={{ cursor: 'pointer' }} variant='body2' noWrap fontSize={16} fontWeight={600} >
										{video?.created_by?.username}
									</Typography>
								</Tooltip>
							</Box>
						</Stack>

						<Stack spacing={1} direction={'row'}>
							<Vote />
							<Button
								variant='soft'
								onClick={() => {
									handleClickOpen()
								}}
							>
								<Iconify icon='mdi-light:share' />
								<Typography variant='body2' fontWeight={600} sx={{ pl: 1 }}>
									Поделиться
								</Typography>
							</Button>
							{video?.can_block && (
								<Button variant='soft' onClick={() => dispatch(setBlock(video.id))}>
									<Iconify icon='material-symbols:block' />
								</Button>
							)}
						</Stack>
					</Stack>
					<Stack>
						<Collapse
							in={checked}
							collapsedSize={80}
							sx={{
								position: 'relative',
								borderRadius: 1,
								px: 1,
								py: 1,
							}}
						>
							<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
								<Stack direction={'row'} spacing={1}>
									<Typography variant={'body2'} fontSize={14} fontWeight={600}>
										{/* {video?.reviews} просмотров */}
										{fViewFormat(video?.reviews ?? 0)}
									</Typography>
									<Typography variant={'body2'} fontSize={14} fontWeight={600}>
										{fToNow(createDateByString(video?.created_date ?? null))}
									</Typography>
								</Stack>
								{!checked && (
									<Button size='small' variant={'text'} onClick={handleChange}>
										Ещё
									</Button>
								)}
							</Stack>
							<Typography
								variant='body2'
								component={'div'}
								sx={{
									textOverflow: 'ellipsis',
								}}
							>
								<Markdown children={video?.description ?? ''} />
							</Typography>
							{checked && (
								<Button size='small' variant={'text'} onClick={handleChange}>
									Свернуть
								</Button>
							)}
						</Collapse>
					</Stack>
					{video?.is_comments ? (
						<Comments Item={'video'}/>
					) : (
						<Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ py: 3 }}>
							<Typography variant='body2' fontSize={16}>
								Комментарии отключены
							</Typography>
						</Stack>
					)}
				</Stack>
				<Stack direction={'column'} sx={{ width: '30%' }}>
					{!isItemPlaylist ? <RecommendVideos /> : <Playlist/>}
				</Stack>
			</Stack>
			<ConfirmDialog
				open={openConfirm}
				onClose={handleCloseConfirm}
				title='Запросить подписку'
				content={<>Вы действительно хотите запросить подписку?</>}
				action={
					<Button
						variant='contained'
						onClick={() => {
							handleCloseConfirm()
						}}
					>
						Запросить
					</Button>
				}
			/>
			{video && <ModalShare open={open} setOpen={setOpen} video={video} />}
		</Stack>
	)
}
