import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { store } from '../store'
import { ITopBlogState } from "../../@types/blog";

const initialState: ITopBlogState = {
    isLoading: false,
    error: null,
    count: 3,
    blogs: [],
}

const slice = createSlice({
    name: 'top_blog',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.blogs = []
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getBlogsSuccess(state, action) {
            state.isLoading = false
            const blogs = action.payload
            state.blogs = blogs
        },
    },
})

// Reducer
export default slice.reducer

export function getTopBlog() {
    return async (dispatch: Dispatch) => {
        const storage = store.getState().top_blogs
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get(`/api/blogs/public/top/${storage.count}`)
            dispatch(slice.actions.getBlogsSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}