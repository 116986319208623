import * as Yup from 'yup'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Box, Button, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useParams } from 'react-router'
import { useDispatch } from 'src/redux/store'
import { createBlogDiscussion, createDiscussion } from 'src/redux/slices/discussion'
import { IDiscussion } from 'src/@types/discussion'
import { useEffect, useMemo } from 'react'

// ----------------------------------------------------------------------

type Props = {
	reply_to: number
	text?: string
	onClose: () => void
	Item: string
}

type FormValuesProps = Pick<IDiscussion, 'reply_to' | 'text'>

export default function CommentFormReply({ reply_to, onClose, Item }: Props) {
	const dispatch = useDispatch()

	const { uuid } = useParams()

	const CommentSchema = Yup.object().shape({
		text: Yup.string().required('Заполните комментарий'),
	})

	const defaultValues = useMemo(
		() => ({
			text: '',
			reply_to: reply_to,
		}),
		[reply_to]
	)

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(CommentSchema),
		defaultValues,
	})

	const {
		reset,
		handleSubmit,
		formState: { isSubmitting },
	} = methods

	const onSubmit = async (data: FormValuesProps) => {
		try {
			await new Promise(resolve => setTimeout(resolve, 500))
			if (Item.includes('blog'))
				await dispatch(createBlogDiscussion(uuid as string, data as IDiscussion))
			else if(Item.includes('video'))
				await dispatch(createDiscussion(uuid as string, data as IDiscussion))
			reset()
			onClose()
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (reply_to) {
			reset(defaultValues)
		}
	}, [reply_to, reset, defaultValues])

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack>
				<Box
					sx={{
						mb: 3,
						ml: 'auto',
						mt: 2,
						width: theme => `calc(100% - ${theme.spacing(7)})`,
					}}
				>
					<RHFTextField
						name='text'
						placeholder='Введите комментарий...'
						multiline
						rows={2}
						fullWidth
						size='small'
					/>
				</Box>
				<Stack
					direction={'row'}
					justifyContent={'end'}
					sx={{
						mb: 3,
						ml: 'auto',
						width: theme => `calc(100% - ${theme.spacing(7)})`,
					}}
					spacing={2}
				>
					<Button
						size={'small'}
						onClick={() => {
							reset()
							onClose()
						}}
					>
						Отмена
					</Button>
					<LoadingButton type='submit' size={'small'} variant='text' loading={isSubmitting}>
						Оставить комментарий
					</LoadingButton>
				</Stack>
			</Stack>
		</FormProvider>
	)
}
