import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import { ISupport, ISupportState } from 'src/@types/support'
import SnackbarUtils from 'src/utils/snackbar'
import axios from 'src/utils/axios'

const initialState: ISupportState = {
	isLoading: false,
	error: null,
	support: null,
	supports: [],
}

const slice = createSlice({
	name: 'support',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getSupport(state, action) {
			state.isLoading = false
			const support = action.payload
			state.support = support
		},

		getSupports(state, action) {
			state.isLoading = false
			const supports = action.payload
			state.supports = supports
		},
	},
})

// Reducer
export default slice.reducer

export function getSupports() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/support`)
			dispatch(slice.actions.getSupports(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getSupport(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/support/${id}`)
			dispatch(slice.actions.getSupport(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function clearSupport() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			dispatch(slice.actions.getSupport(null))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function newSupport(data: ISupport) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(`/api/support/`, data)
			SnackbarUtils.success('Ваше обращение отправленно')
		} catch (error) {
			SnackbarUtils.error('Ошибка при отправке обращения')
			dispatch(slice.actions.hasError(error))
		}
	}
}

export const getAccountFull = createAsyncThunk(
	'getAccountFullThunk',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(`api/oauth/userinfo`)
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export function closeClaim(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/support/${id}`)
			dispatch(slice.actions.getSupports(response.data))
			SnackbarUtils.success('Заявка закрыта')
		} catch (error) {
			SnackbarUtils.error('Ошибка при закрытие заявки')
			dispatch(slice.actions.hasError(error))
		}
	}
}
