import React from 'react'
// @mui
import { Box } from '@mui/material'
// components
import { Outlet } from 'react-router-dom'

// ----------------------------------------------------------------------

export default function EmbeddedLayout() {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
			<Box
				component='main'
				sx={{
					display: 'flex',
					flexGrow: 1,
					position: 'fixed',
					right: 0,
					bottom: 0,
					minWidth: '100%',
					minHeight: '100%',
				}}
			>
				<Outlet />
			</Box>
		</Box>
	)
}
