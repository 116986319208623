import * as Yup from 'yup'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useParams } from 'react-router'
import { useDispatch } from 'src/redux/store'
import { createBlogDiscussion, createDiscussion } from 'src/redux/slices/discussion'
import { IDiscussion } from 'src/@types/discussion'

// ----------------------------------------------------------------------

type FormValuesProps = {
	text: string
}

type Props = {
	Item: string
}

export default function CommentForm({ Item } : Props) {
	const dispatch = useDispatch()

	const { uuid } = useParams()

	const CommentSchema = Yup.object().shape({
		text: Yup.string().required('Заполните комментарий'),
	})

	const defaultValues = {
		text: '',
	}

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(CommentSchema),
		defaultValues,
	})

	const {
		reset,
		handleSubmit,
		formState: { isSubmitting },
	} = methods

	const onSubmit = async (data: FormValuesProps) => {
		try {
			if (Item.includes('blog'))
				await dispatch(createBlogDiscussion(uuid as string, data as IDiscussion))
			else if(Item.includes('video'))
				await dispatch(createDiscussion(uuid as string, data as IDiscussion))

			reset()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={1} alignItems='flex-end'>
				<RHFTextField name='text' placeholder='Введите комментарий...' multiline rows={3} />

				<LoadingButton type='submit' variant='contained' loading={isSubmitting}>
					Оставить комментарий
				</LoadingButton>
			</Stack>
		</FormProvider>
	)
}
