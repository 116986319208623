import { memo } from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

function OKIcon({ ...other }: BoxProps) {
	return (
		<Box {...other}>
			<svg
				viewBox='0 0 60 60'
				xmlns='http://www.w3.org/2000/svg'
				focusable='false'
				width='100%'
				height='100%'
			>
				<g>
					<g fill='none' fillRule='evenodd'>
						<circle fill='#F7931F' cx='30' cy='30' r='30' />
						<path
							d='M30.02666667 16.01333333c3.71666663.01 6.70333333 3.05 6.68333333 6.81333334C36.69 26.5 33.6566667 29.4766667 29.94333333 29.4633333c-3.67666666-.0133333-6.69-3.06-6.66666666-6.73999997.02333333-3.72333333 3.04-6.72 6.75-6.71zm-.01 10.01666667C31.84 26.0233333 33.2933333 24.56 33.2866667 22.73333333 33.28 20.90666667 31.82333333 19.45 30 19.45c-1.84-.00333333-3.31 1.48-3.29333333 3.32333333.01333333 1.82 1.48333333 3.26333337 3.31 3.25666667zM37.56 32.1066667C36.7366667 32.95 35.7466667 33.5633333 34.65 33.99c-1.04.4033333-2.1766667.6066667-3.30666667.74.17.1866667.25.2766667.35666667.3833333 1.53 1.5366667 3.0666667 3.07 4.5933333 4.61.52.5266667.63 1.1766667.3433334 1.7866667-.3133334.67-1.0133334 1.1066667-1.7 1.06-.4366667-.03-.7733334-.2466667-1.0766667-.55-1.1566667-1.1633333-2.33333333-2.3033333-3.46333333-3.4866667-.33-.3433333-.48666667-.28-.78.02-1.16333334 1.1966667-2.34 2.3733334-3.53 3.5433334-.53333334.5266666-1.16666667.62-1.78666667.32-.65666667-.32-1.07666667-.99-1.04333333-1.6633334.02333333-.4566666.24666666-.8033333.56-1.1166666C25.33 38.1233333 26.84 36.61 28.35333333 35.0933333c.1-.1.19333334-.2066666.34-.3633333-2.06-.2166667-3.91666666-.7233333-5.50666666-1.9666667-.19666667-.1533333-.4-.3033333-.58333334-.4766666-.69666666-.67-.76666666-1.4333334-.21333333-2.2233334.47-.6733333 1.26333333-.8566666 2.08333333-.47.16.0733334.31.17.45666667.27 2.96333333 2.0366667 7.03666667 2.0933334 10.01.0933334.2933333-.2233334.61-.41.9766667-.5033334.71-.1833333 1.37.0766667 1.7533333.7.4333333.71.4266667 1.4-.11 1.9533334z'
							fill='#FFF'
							fillRule='nonzero'
						/>
					</g>
				</g>
			</svg>
		</Box>
	)
}

export default memo(OKIcon)
