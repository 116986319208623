import { useDropzone } from 'react-dropzone'
// @mui
import { Box, Stack, Button, IconButton, Typography, StackProps } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
// assets
import { UploadIllustration } from '../../assets/illustrations'
//
import { UploadProps } from './types'
import RejectionFiles from './errors/RejectionFiles'
import MultiFilePreview from './preview/MultiFilePreview'
import SingleFilePreview from './preview/SingleFilePreview'
import LinearProgressWithLabel from 'src/components/progress-bar/LinearProgressWithLabel'
import SvgColor from 'src/components/svg-color/SvgColor'

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
	outline: 'none',
	cursor: 'pointer',
	overflow: 'hidden',
	position: 'relative',
	padding: theme.spacing(5, 1),
	borderRadius: theme.shape.borderRadius,
	transition: theme.transitions.create('padding'),
	backgroundColor: theme.palette.background.neutral,
	border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
	'&:hover': {
		opacity: 0.72
	}
}))

// ----------------------------------------------------------------------

export default function Upload({
	disabled,
	multiple = false,
	error,
	helperText,
	//
	file,
	onDelete,
	//
	files,
	thumbnail,
	onUpload,
	onRemove,
	onRemoveAll,
	sx,
	loadPreview,
	loaded,
	total,
	...other
}: UploadProps) {
	const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
		multiple,
		disabled,
		...other
	})

	const hasFile = !!file && !multiple

	const hasFiles = files && multiple && files.length > 0

	const isError = isDragReject || !!error

	return (
		<Box sx={{ width: 1, position: 'relative', ...sx }}>
			{!loadPreview && (
				<StyledDropZone
					{...getRootProps()}
					sx={{
						...(isDragActive && {
							opacity: 0.72
						}),
						...(isError && {
							color: 'error.main',
							bgcolor: 'error.lighter',
							borderColor: 'error.light'
						}),
						...(disabled && {
							opacity: 0.48,
							pointerEvents: 'none'
						}),
						...(hasFile && {
							padding: '12% 0'
						})
					}}
				>
					<input {...getInputProps()} />
					<Placeholder
						sx={{
							...(hasFile && {
								opacity: 0
							})
						}}
						helper_text={helperText}
					/>

					{hasFile && <SingleFilePreview file={file} />}
				</StyledDropZone>
			)}

			<RejectionFiles fileRejections={fileRejections} />

			{hasFile && onDelete && (
				<IconButton
					size='small'
					onClick={onDelete}
					sx={{
						top: 16,
						right: 16,
						zIndex: 9,
						position: 'absolute',
						color: theme => alpha(theme.palette.common.white, 0.8),
						bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
						'&:hover': {
							bgcolor: theme => alpha(theme.palette.grey[900], 0.48)
						}
					}}
				>
					<SvgColor src='/assets/icons/material/close.svg' />
				</IconButton>
			)}

			{hasFiles && (
				<>
					<Box sx={{ my: 3 }}>
						<MultiFilePreview files={files} thumbnail={thumbnail} onRemove={onRemove} />
					</Box>

					<Stack direction='row' justifyContent='flex-end' spacing={1.5}>
						{onRemoveAll && (
							<Button color='error' variant='soft' size='small' onClick={onRemoveAll}>
								Удалить все
							</Button>
						)}

						{onUpload && (
							<Button size='small' variant='contained' onClick={onUpload}>
								Загрузить
							</Button>
						)}
					</Stack>
				</>
			)}

			{loadPreview && <Loader total={total} loaded={loaded} />}
		</Box>
	)
}

// ----------------------------------------------------------------------
type IProps = Omit<StackProps, 'helper_text'>

interface PlaceholderProps extends IProps {
	helper_text?: React.ReactNode
}

function Placeholder({ sx, helper_text, ...other }: PlaceholderProps) {
	return (
		<Stack
			spacing={2}
			alignItems='center'
			justifyContent='center'
			direction={{
				xs: 'column',
				md: 'row'
			}}
			sx={{
				width: 1,
				textAlign: {
					xs: 'center',
					md: 'left'
				},
				...sx
			}}
			{...other}
		>
			<UploadIllustration sx={{ width: 220 }} />

			<Box sx={{ p: 3 }}>
				<Typography gutterBottom variant='h5'>
					Выберите файл
				</Typography>

				<Typography variant='body2' sx={{ color: 'text.secondary' }}>
					Перетащите файл в данную область или
					<Typography
						variant='body2'
						component='span'
						sx={{
							mx: 0.5,
							color: 'primary.main',
							textDecoration: 'underline'
						}}
					>
						выберите
					</Typography>
					на вашем компьютере
				</Typography>
				{helper_text && helper_text}
			</Box>
		</Stack>
	)
}

// ----------------------------------------------------------------------
type ILoadedProps = Omit<StackProps, 'total' | 'loaded'>

interface LoaderProps extends ILoadedProps {
	total: number | null | undefined
	loaded: number | null | undefined
}

function Loader({ sx, total, loaded }: LoaderProps) {
	const progress = (loaded: number | null | undefined, total: number | null | undefined) => {
		if (loaded && total) {
			return total !== 0 ? (loaded / total) * 100 : 0
		}
		return 0
	}

	return (
		<Stack
			spacing={2}
			alignItems='center'
			justifyContent='center'
			direction={'row'}
			sx={{
				p: 1,
				width: '100%',
				...sx
			}}
		>
			{<LinearProgressWithLabel value={progress(loaded, total)} />}
		</Stack>
	)
}
