import React, {
    ReactNode,
    createContext,
    useState,
    useContext,
    useEffect,
    useMemo,
} from "react";
import WebSocketClient, { Status } from "../utils/websocket";
import { WS_HOST } from "src/config-global";
import { useAuthContext } from "./useAuthContext";

type WSContextType = {
    ws: WebSocketClient | null;
    isReady: boolean
    isAuthReady: boolean
};

type Props = {
    children: ReactNode;
};

const WSContext = createContext<WSContextType>({
    ws: null,
    isReady: false,
    isAuthReady: false,
});

export const WSProvider = ({ children }: Props) => {
    const [isReady, setIsReady] = useState<boolean>(false)
    const [isAuthReady, setIsAuthReady] = useState<boolean>(false)
    const { isAuthenticated } = useAuthContext()

    const ws = useMemo(() => new WebSocketClient(WS_HOST), []);

    useEffect(() => {
        ws.onStatusChanged = (status: number) => {
            if (status === Status.CLOSE) {
                setIsReady(false)
                setIsAuthReady(false)
            }
            else if (status === Status.OPEN) setIsReady(true)
        }
        ws.onAuthSuccess = () => {
            ws.whoiam()
            setIsAuthReady(true)
        }
    }, [ws]);

    useEffect(() => {
        ws.connect()
    }, [ws]);

    useEffect(() => () => ws.reconnect(), [ws, isAuthenticated]);

    const value = useMemo(
        () => ({
            ws,
            isReady,
            isAuthReady,
        }),
        [ws, isReady, isAuthReady]
    );

    return <WSContext.Provider value={value}>{children}</WSContext.Provider>;
};

export const useWS = () => useContext(WSContext);
