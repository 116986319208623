import React from 'react'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Stack sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
			<Box sx={{ minWidth: 100 }}>
				<Typography variant='body2' color='text.secondary'>
					Загрузка файла...
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
				<Box sx={{ width: '100%', mr: 1 }}>
					<LinearProgress variant='determinate' {...props} />
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant='body2' color='text.secondary'>{`${Math.round(
						props.value
					)}%`}</Typography>
				</Box>
			</Box>
		</Stack>
	)
}

export default LinearProgressWithLabel
