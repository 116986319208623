// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
	return `${root}${sublink}`
}

const ROOT_PATH = ''
const ROOTS_ADMINISTRATION = '/administration'
const ROOTS_AUTH = '/auth'
const ROOTS_ACCOUNT = '/account'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, '/login'),
	register: path(ROOTS_AUTH, '/register'),
	registerSuccess: path(ROOTS_AUTH, '/register-success'),
	registerError: path(ROOTS_AUTH, '/register-error'),
	verify: path(ROOTS_AUTH, '/verify'),
	resetPassword: path(ROOTS_AUTH, '/reset-password'),
	newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_ACCOUNT = {
	root: ROOTS_ACCOUNT,
}

export const PATH_PAGE = {
	home: path(ROOT_PATH, '/home'),
	videos: path(ROOT_PATH, '/videos'),
	broadcast: path(ROOT_PATH, '/broadcast'),
	channel: (uuid: string) => path(ROOT_PATH, `/channel/${uuid}`),
	watch: (uuid: string) => path(ROOT_PATH, `/watch/${uuid}`),
	library: path(ROOT_PATH, '/library'),
	subscriptions: path(ROOT_PATH, '/subscriptions'),
	history: path(ROOT_PATH, '/history'),
	liked: path(ROOT_PATH, '/liked'),
	chat: {
		root: path(ROOT_PATH, '/chat'),
		view: (uuid: string) => path(ROOT_PATH, `/chat/${uuid}`),
	},
	moderation: path(ROOT_PATH, '/moderation'),
	embedded: {
		stream: (uuid: string) => path(ROOT_PATH, `/embedded/stream/${uuid}`),
		video: (uuid: string) => path(ROOT_PATH, `/embedded/video/${uuid}`),
	},
	stream: {
		root: path(ROOT_PATH, '/content'),
		edit: (uuid: string) => path(ROOT_PATH, `/content/stream/${uuid}/edit`),
		watch: (uuid: string) => path(ROOT_PATH, `/stream/${uuid}`),
	},
	content: {
		root: path(ROOT_PATH, '/content'),
		edit: (uuid: string) => path(ROOT_PATH, `/content/video/${uuid}/edit`),
	},
	playlist: {
		root: path(ROOT_PATH, '/content'),
		edit: (uuid: string) => path(ROOT_PATH, `/content/playlist/${uuid}/edit`),
		watch: (id: string, uuid: string) => path(ROOT_PATH, `/playlist/${id}/${uuid}`),
	},
	blog: {
		root: path(ROOT_PATH, '/content'),
		edit: (uuid: string) => path(ROOT_PATH, `/content/blog/${uuid}/edit`),
	},
	search: (search_query: string) => path(ROOT_PATH, `/results?${search_query}`),
	blogs: {
		root: path(ROOT_PATH, '/blogs'),
		read: (uuid: string) => path(ROOT_PATH, `/blogs/${uuid}`),
	},
	support: path(ROOT_PATH, '/support'),
}

export const PATH_ADMINISTRATION = {
	root: ROOTS_ADMINISTRATION,
	subsribes: {
		root: path(ROOTS_ADMINISTRATION, '/subscribes'),
		view: (uuid: string) => path(ROOTS_ADMINISTRATION, `/subscribes/${uuid}`),
	},
	advertising: {
		root: path(ROOTS_ADMINISTRATION, '/advertising'),
		edit: (uuid: string) => path(ROOTS_ADMINISTRATION, `/advertising/${uuid}`),
		new: path(ROOTS_ADMINISTRATION, '/advertising/new'),
	},
	support: {
		root: path(ROOTS_ADMINISTRATION, '/support'),
		view: (uuid: string) => path(ROOTS_ADMINISTRATION, `/support/${uuid}`),
	},
}
