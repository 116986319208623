import { useState } from 'react'
// @mui
import { Stack, Button, Divider, ListItem, Typography, IconButton } from '@mui/material'
// utils
import { fDate } from 'src/utils/formatTime'
import { IUserShort } from 'src/@types/discussion'
import { CustomAvatar } from 'src/components/custom-avatar'
import CommentFormReply from './CommentFormReply'
import { useAuthContext } from 'src/auth/useAuthContext'
import Iconify from 'src/components/iconify'
import { useDispatch } from 'src/redux/store'
import { removeBlogDisscussion, removeDisscussion, setBlock } from 'src/redux/slices/discussion'
import { useParams } from 'react-router'

// ----------------------------------------------------------------------

type Props = {
	id: number
	message: string
	created_date: string | null
	created_by: IUserShort
	canBlock: boolean
	hasReply?: boolean
	Item: string
}

export default function CommentItem({
	id,
	created_by,
	message,
	created_date,
	canBlock,
	hasReply,
	Item
}: Props) {
	const dispatch = useDispatch()

	const { user } = useAuthContext()

	const { uuid } = useParams()

	const [openReply, setOpenReply] = useState(false)

	const handleRemoveComment = (id: number) => {
		if (Item.includes('blog'))
			dispatch(removeBlogDisscussion(uuid as string, id))
		else if(Item.includes('video'))
			dispatch(removeDisscussion(uuid as string, id))
	}

	return (
		<>
			<ListItem
				disableGutters
				sx={{
					alignItems: 'flex-start',
					py: 3,
					...(hasReply && {
						width: 'auto',
						marginLeft: 8,
					}),
				}}
			>
				<CustomAvatar
					name={created_by!.username}
					alt={created_by!.username}
					src={created_by!.avatar}
					sx={{ mr: 2, width: 48, height: 48, ml: 2 }}
				/>

				<Stack>
					<Typography variant='subtitle1'> {created_by!.username} </Typography>

					<Typography variant='caption' sx={{ color: 'text.disabled' }}>
						{fDate(created_date)}
					</Typography>

					<Typography variant='body2' sx={{ mt: 1 }}>
						{/* {tagUser && (
							<Box component='strong' sx={{ mr: 0.5 }}>
								@{tagUser}
							</Box>
						)} */}
						{message}
					</Typography>
				</Stack>

				<Stack direction={'row'} spacing={1} sx={{ right: 0, position: 'absolute' }}>
					{!hasReply && user && (
						<Button size='small' onClick={() => setOpenReply(!openReply)}>
							Ответить
						</Button>
					)}

					{created_by.id === user?.id && (
						<IconButton
							size='small'
							onClick={() => {
								handleRemoveComment(id)
							}}
							sx={{ color: 'red' }}
						>
							<Iconify icon='ph:trash' />
						</IconButton>
					)}
					{canBlock && (
						<Button onClick={() => dispatch(setBlock(id))}>
							<Iconify icon='material-symbols:block' />
						</Button>
					)}
				</Stack>
			</ListItem>

			{openReply && <CommentFormReply Item={Item} reply_to={id} onClose={() => setOpenReply(!openReply)} />}

			<Divider
				sx={{
					...(hasReply && {
						ml: 7,
					}),
				}}
			/>
		</>
	)
}
