import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	Link,
} from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import FrameIcon from 'src/assets/icons/FrameIcon'
import MailIcon from 'src/assets/icons/MailIcon'
import OKIcon from 'src/assets/icons/OKIcon'
import VkontakteIcon from 'src/assets/icons/VkontakteIcon'
import WhatspappIcon from 'src/assets/icons/WhatspappIcon'
import { HOST_API_KEY, SITE_TITLE } from 'src/config-global'
import { PATH_PAGE } from 'src/routes/paths'
import { useSnackbar } from 'src/components/snackbar'
import useCopyToClipboard from 'src/hooks/useCopyToClipboard'
// types
import { ModalShareProps } from './types'
import Iconify from 'src/components/iconify'
import TelegramIcon from 'src/assets/icons/TelegramIcon'

const ModalShare: FC<ModalShareProps> = ({ video, open, setOpen }) => {
	const { enqueueSnackbar } = useSnackbar()

	const [openFrame, setOpenFrame] = useState<boolean>(false)

	const [frame, setFrame] = useState<string>('')

	const { copy } = useCopyToClipboard()

	const { id } = video

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		if (id) {
			setFrame(getFrame(id))
		}
	}, [id, openFrame, setFrame])

	const getFrame = (id: string) =>
		`<iframe width="560" height="315" src="${HOST_API_KEY}${PATH_PAGE.embedded.video(
			id
		)}" title="${SITE_TITLE}" video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={'sm'}
				fullWidth={true}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{'Поделиться'}</DialogTitle>
				<IconButton sx={{ position: 'absolute', right: '18px', top: '18px' }} onClick={handleClose}>
					<Iconify icon='eva:close-fill' />
				</IconButton>
				<DialogContent>
					<Stack direction={'row'} spacing={2} py={1} justifyContent={'space-around'}>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							gap={0.5}
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								setOpen(false)
								setOpenFrame(true)
							}}
						>
							<FrameIcon sx={{ width: 60, height: 60 }} />
							<Typography variant='body2'>Встроить</Typography>
						</Box>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							gap={0.5}
							sx={{
								cursor: 'pointer',
							}}
							onClick={() => {
								window.open(
									`https://api.whatsapp.com/send/?text=${HOST_API_KEY}${PATH_PAGE.watch(id ?? '')}`,
									'_blank',
									'noreferrer'
								)
							}}
						>
							<WhatspappIcon sx={{ width: 60, height: 60 }} />
							<Typography variant='body2'>Whatsapp</Typography>
						</Box>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							gap={0.5}
							sx={{
								cursor: 'pointer',
							}}
							onClick={() => {
								window.open(
									`https://vk.com/share.php?url=${HOST_API_KEY}${PATH_PAGE.watch(video?.id ?? '')}`,
									'_blank',
									'noreferrer'
								)
							}}
						>
							<VkontakteIcon sx={{ width: 60, height: 60 }} />
							<Typography variant='body2'>ВКонтакте</Typography>
						</Box>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							gap={0.5}
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								window.open(
									`https://connect.ok.ru/offer?url=${HOST_API_KEY}${PATH_PAGE.watch(id ?? '')}`,
									'_blank',
									'noreferrer'
								)
							}}
						>
							<OKIcon sx={{ width: 60, height: 60 }} />
							<Typography variant='body2'>ОК</Typography>
						</Box>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							gap={0.5}
							sx={{ cursor: 'pointer' }}
							onClick={() => {
								window.open(
									`https://t.me/share/url?url=${HOST_API_KEY}${PATH_PAGE.watch(id ?? '')}&text=${
										video?.name
									}`,
									'_blank',
									'noreferrer'
								)
							}}
						>
							<TelegramIcon sx={{ width: 60, height: 60 }} />
							<Typography variant='body2'>Telegram</Typography>
						</Box>
						<Box
							display={'flex'}
							alignItems={'center'}
							flexDirection={'column'}
							gap={0.5}
							sx={{
								cursor: 'pointer',
							}}
							onClick={() => {
								window.open(
									`mailto:?body=${HOST_API_KEY}${PATH_PAGE.watch(video?.id ?? '')}`,
									'_blank',
									'noreferrer'
								)
							}}
						>
							<MailIcon sx={{ width: 60, height: 60 }} />
							<Typography variant='body2'>Электронная почта</Typography>
						</Box>
					</Stack>
					<TextField
						disabled
						value={`${HOST_API_KEY}${PATH_PAGE.watch(video?.id ?? '')}`}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										onClick={() => {
											enqueueSnackbar('Ссылка скопирована в буфер обмена')
											copy(`${HOST_API_KEY}${PATH_PAGE.watch(video?.id ?? '')}`)
										}}
										edge='end'
									>
										<Iconify icon={'ph:copy'} />
									</IconButton>
								</InputAdornment>
							),
						}}
						sx={{ width: '100%' }}
					/>
				</DialogContent>
				<DialogActions />
			</Dialog>
			<Dialog
				open={openFrame}
				maxWidth={'sm'}
				fullWidth={true}
				onClose={() => {
					setOpenFrame(false)
				}}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>{'Встраивание видео'}</DialogTitle>
				<DialogContent>
					<Stack gap={0.5}>
						<Typography variant='body2'>
							{`Встраивая видео ${SITE_TITLE} на свой сайт, вы принимаете `}{' '}
							<Link href='/term_of_use' underline='hover'>
								Условия использования
							</Link>{' '}
							{` ${SITE_TITLE}.`}
						</Typography>
						<TextField
							fullWidth
							multiline
							rows={6}
							value={frame}
							sx={{ fontStyle: 'italic' }}
							onChange={e => {
								setFrame(e.target.value)
							}}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpenFrame(false)
						}}
					>
						Отмена
					</Button>
					<Button
						onClick={() => {
							enqueueSnackbar('Скопировано')
							copy(frame)
						}}
						autoFocus
					>
						Скопировать
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ModalShare
