import React, { Dispatch, FC, forwardRef, SetStateAction } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Dialog, DialogTitle, IconButton, Slide } from '@mui/material'
import Iconify from '../iconify/Iconify'
import { useDispatch, useSelector } from 'src/redux/store'
import DialogUploadVideo from './DialogUploadVideo'
import { cleanUpload } from 'src/redux/slices/upload'
import DialogUpdateVideo from './DialogUpdateVideo'
//---------------------------------------------------------------------------------------

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='down' ref={ref} {...props} />
})

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

const ModalAddVideo: FC<Props> = ({ open, setOpen }) => {
	const dispatch = useDispatch()

	const { video } = useSelector(state => state.upload)

	const handleCloseModal = (_: {}, reason: string) => {
		if (reason === 'backdropClick') {
			return false
		}

		if (reason === 'escapeKeyDown') {
			return false
		}

		handleClose()
	}

	const onClose = (_: React.MouseEvent) => {
		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		dispatch(cleanUpload())
	}

	return (
		<Dialog
			fullWidth
			maxWidth={'md'}
			TransitionComponent={Transition}
			open={open}
			disableEscapeKeyDown
			onClose={handleCloseModal}
		>
			<DialogTitle>{'Загрузка видео'}</DialogTitle>
			<IconButton sx={{ position: 'absolute', right: '18px', top: '18px' }} onClick={onClose}>
				<Iconify icon='eva:close-fill' />
			</IconButton>
			{!video ? <DialogUploadVideo /> : <DialogUpdateVideo handleClose={handleClose} />}
		</Dialog>
	)
}

export default ModalAddVideo
