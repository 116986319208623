type ISnackbarUtils = {
	enqueueSnackbar: Function
	closeSnackbar: Function
}
class SnackbarUtils {
	snackBar: ISnackbarUtils

	constructor() {
		this.snackBar = {
			enqueueSnackbar: () => {},
			closeSnackbar: () => {}
		}
	}

	setSnackBar(enqueueSnackbar: Function, closeSnackbar: Function) {
		this.snackBar.enqueueSnackbar = enqueueSnackbar
		this.snackBar.closeSnackbar = closeSnackbar
	}

	success(msg: string, options = {}) {
		return this.toast(msg, { ...options, variant: 'success' })
	}

	warning(msg: string, options = {}) {
		return this.toast(msg, { ...options, variant: 'warning' })
	}

	info(msg: string, options = {}) {
		return this.toast(msg, { ...options, variant: 'info' })
	}

	error(msg: string, options = {}) {
		return this.toast(msg, { ...options, variant: 'error' })
	}

	toast(msg: string, options = {}) {
		const finalOptions = {
			variant: 'default',
			...options
		}
		return this.snackBar.enqueueSnackbar(msg, { ...finalOptions })
	}

	closeSnackbar(key: string) {
		this.snackBar.closeSnackbar(key)
	}
}

export default new SnackbarUtils()
