import React, { memo, KeyboardEvent, useState, useEffect } from 'react'
// components
import Iconify from 'src/components/iconify/Iconify'
// mui
import { InputBase, ButtonBase, InputAdornment, IconButton } from '@mui/material'
// redux
import { PATH_PAGE } from 'src/routes/paths'
import { useNavigate } from 'react-router'
import { useSelector } from 'src/redux/store'
//

const SearchInput = () => {
	const navigate = useNavigate()

	const stateSearch = useSelector(state => state.search)

	const [search, setSearch] = useState<string>('')

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value)
	}

	useEffect(() => {
		setSearch(stateSearch.search)
	}, [stateSearch.search])

	const handleSearch = () => {
		if (search) navigate(PATH_PAGE.search(`search_query=${search}`))
		else navigate(PATH_PAGE.home)
	}

	const handleClearSearch = () => {
		setSearch('')
		handleSearch()
	}

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleSearch()
		}
	}

	return (
		<>
			<InputBase
				placeholder='Введите запрос'
				sx={{
					height: 40,
					border: '1px solid #ccc',
					borderRadius: '40px 0 0 40px',
					px: 2,
					flex: 1,
				}}
				value={search}
				endAdornment={
					search && (
						<InputAdornment position='end'>
							<IconButton
								aria-label='toggle password visibility'
								onClick={handleClearSearch}
								edge='end'
							>
								<Iconify icon={'ic:outline-clear'} />
							</IconButton>
						</InputAdornment>
					)
				}
				onKeyDown={handleKeyDown}
				onChange={handleChangeInput}
			/>
			<ButtonBase
				sx={{
					height: 40,
					border: '1px solid #ccc',
					borderLeft: 0,
					borderRadius: '0 40px 40px 0',
					px: 2,
				}}
				onClick={handleSearch}
			>
				<Iconify icon='ic:baseline-search' />
			</ButtonBase>
		</>
	)
}

export default memo(SearchInput)
