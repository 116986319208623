import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'

export type IMetricsState = {
	isLoading: boolean
	error: Error | string | null
	metric: string
}

const initialState: IMetricsState = {
	isLoading: false,
	error: null,
	metric: '',
}

const slice = createSlice({
	name: 'metrics',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.metric = ''
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getMetrics(state, action) {
			state.isLoading = false
			const metric = action.payload
			state.metric = metric.metric
		},
	},
})

// Reducer
export default slice.reducer

export function getMetrics() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/metrics`)
			dispatch(slice.actions.getMetrics(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateMetrics(metric: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`api/metrics/`, { metric: metric })
			dispatch(slice.actions.getMetrics(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
