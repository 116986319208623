import React, { Dispatch, FC, forwardRef, SetStateAction, useMemo, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import {
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Stack
} from '@mui/material'
import Iconify from '../iconify/Iconify'
import { useDispatch } from 'src/redux/store'
import FormProvider, { RHFSwitch, RHFTextField } from "../hook-form";
import { Controller, useForm } from "react-hook-form";
import Editor from "../editor";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { PATH_PAGE } from "../../routes/paths";
import { useNavigate } from "react-router-dom";
import { createBlog } from "../../redux/slices/private_blog";
import { IBlog } from "../../@types/blog";
import { Upload, UploadProps } from "../upload";
import SnackbarUtils from "../../utils/snackbar";
import CustomAutocomplete from "../autocomplete";
import { ITag, ITagInput } from "../../@types/tag";
import { createTag, getTagsThunk } from "../../redux/slices/tag";
//---------------------------------------------------------------------------------------

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />
})

interface Props {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}
interface FormValuesProps extends Pick<UploadProps, 'file'>{
    name: string
    description: string
    text: string
    is_comments: boolean
    is_published: boolean
    blog_cover: File | null
    tags_ids: ITag[]
}
const ModalAddBlog: FC<Props> = ({ open, setOpen }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isSetting, setIsSettings] = useState(false)
    const [currentCover, setCurrentCover] = useState('')

    const defaultValues = useMemo(
        () => ({
            name: '',
            description: '',
            text: '',
            is_comments: true,
            is_published: false,
            tags_ids: []
        }),
        []
    )

    const UpdateSchema = Yup.object().shape({
        name: Yup.string().nullable().required('Заполните Наименование'),
    })

    const filter = createFilterOptions<ITag>();
    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(UpdateSchema),
        defaultValues,
    })

    const {
        handleSubmit,
        reset,
        control,
        formState: { isSubmitting }, //isValid
    } = methods

    const handleCloseModal = (_: {}, reason: string) => {
        if (reason === 'backdropClick') {
            return false
        }

        if (reason === 'escapeKeyDown') {
            return false
        }

        handleClose()
    }

    const onClose = (_: React.MouseEvent) => {
        handleClose()
    }

    const handleClose = () => {
        setOpen(false)
        setIsSettings(false)
        setCurrentCover('')
        reset({ name: '', description: '', text: '' })
    }
    const handleCreate = async (data: FormValuesProps) => {
        const { name, description, text, is_comments, is_published, tags_ids } = data
        if (currentCover || !is_published) {
            const blog = {
                name: name,
                description: description,
                text: text,
                blog_cover: currentCover,
                is_comments: is_comments,
                is_published: is_published,
                tags_ids: tags_ids
            }
            dispatch(createBlog(blog as IBlog))
            navigate(PATH_PAGE.blogs.root)
            handleClose()
        }
        else
            SnackbarUtils.error('Выберите обложку для публикации')
    }

    const handleSetSettings = () => {
        setIsSettings(!isSetting)
    }
    const blobToBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleDrop = async (acceptedFiles: File[]) => {
        const blog_cover = acceptedFiles[0]
        const f = await blobToBase64(blog_cover)
        // setValue('file', blog_cover)
        setCurrentCover(f as string)
    }

    const onLoadOptions = async (controller: AbortController) => {
        try {
            const resultAction = await dispatch(getTagsThunk(controller))
            if (getTagsThunk.fulfilled.match(resultAction)) {
                return resultAction.payload
            } else {
                return []
            }
        } catch (err) {
            return []
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            TransitionComponent={Transition}
            open={open}
            disableEscapeKeyDown
            onClose={handleCloseModal}
        >
            <DialogTitle>{'Создание блога'}</DialogTitle>
            <IconButton sx={{ position: 'absolute', right: '18px', top: '18px' }} onClick={onClose}>
                <Iconify icon='eva:close-fill' />
            </IconButton>
            {isSetting ? <>
                    <DialogContent sx={{ overflow: 'unset', pb: 3 }}>
                    <FormProvider methods={methods}>
                        <Stack direction='column' spacing={2}>

                            <Controller
                                control={control}
                                name='description'
                                render={({ field }) => (
                                    <Editor
                                        id='blog-editor'
                                        value={field.value.substring(0, 3000)}
                                        simple
                                        onChange={value => field.onChange(value)}
                                        placeholder={'Введите краткое описание'}
                                    />
                                )}
                            />
                            <Upload
                                file={currentCover}
                                onDrop={handleDrop}
                                accept={{ 'image/*': [] }}
                                sx={{
                                    flex: '1 1 auto',
                                    display: 'flex',
                                    flexDirection: 'column',

                                    '& > *:first-of-type': {
                                        flex: '1 1 auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },

                                    '& img': {
                                        objectFit: 'fill'
                                    },
                                }}
                            />
                            <Controller
                                control={control}
                                name='tags_ids'
                                render={({ field }) =>
                                    (
                                    <CustomAutocomplete<ITag>
                                        multiple
                                        value={field.value}
                                        onLoadOptions={onLoadOptions}
                                        onChange={(_, v) => {
                                            if((v as ITagInput[]).slice(-1)[0] && (v as ITagInput[]).slice(-1)[0].inputValue)
                                                dispatch(createTag({ name: (v as ITagInput[]).slice(-1)[0].inputValue } as ITag)).then((result)=> field.onChange([...field.value, result]))
                                            else
                                                field.onChange(v as ITag[])
                                        }}
                                        filterOptions={(options, params, loading) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.name);
                                            if (inputValue !== '' && !isExisting && !loading)
                                                filtered.push({
                                                    inputValue: inputValue,
                                                    name: `Добавить тег "${inputValue}"`,
                                                } as ITagInput);
                                            return filtered;
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        label='Теги'
                                        placeholder='Выберите теги'
                                        getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
                                    />
                                )}
                            />
                            <RHFSwitch name='is_published' label='Опубликовать' />
                            <RHFSwitch name='is_comments' label='Разрешить комментарии' />
                        </Stack>
                    </FormProvider>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} justifyContent={'start'}>
                        <LoadingButton
                            size='medium'
                            variant='contained'
                            onClick={() => handleSetSettings()}
                        >
                            Назад
                        </LoadingButton>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'end'}>
                        <LoadingButton
                            size='medium'
                            variant='contained'
                            loading={isSubmitting}
                            onClick={handleSubmit(handleCreate)}
                        >
                            Создать
                        </LoadingButton>
                    </Stack>
                </DialogActions>
                </>
                :
                <>
                <DialogContent sx={{ overflow: 'unset', pb: 3 }}>
                <FormProvider methods={methods}>
                    <Stack direction='column' spacing={2}>
                        <RHFTextField name='name' label='Заголовок' sx={{ borderColor: 'transparent' }} />
                        <Controller
                            control={control}
                            name='text'
                            render={({ field }) => (
                                <Editor
                                    id='blog-editor-2'
                                    value={field.value}
                                    simple
                                    onChange={value => field.onChange(value)}
                                    placeholder={'Содержимое блога'}
                                />
                            )}
                        />
                    </Stack>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} justifyContent={'end'}>
                    <LoadingButton
                        size='medium'
                        variant='contained'
                        onClick={() => handleSetSettings()}
                    >
                        Настроить
                    </LoadingButton>
                </Stack>
            </DialogActions>
                </>
            }
        </Dialog>
    )
}

export default ModalAddBlog
