import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IStream } from 'src/@types/stream'
import { IVideo } from 'src/@types/video'
import { dispatch as dispatchStore, store } from '../store'

type ISearchState = {
	isLoading: boolean
	error: Error | string | null
	search: string
	videos: IVideo[] | IStream[]
	type_search: 'videos' | 'streams'
}

const initialState: ISearchState = {
	isLoading: false,
	error: null,
	search: '',
	videos: [],
	type_search: 'videos',
}

const slice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.videos = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		setSearchValue(state, action) {
			const search = action.payload
			state.search = search
		},

		setType(state, action) {
			const type_search = action.payload
			state.type_search = type_search
		},

		getVideosSuccess(state, action) {
			state.isLoading = false
			const videos = action.payload
			state.videos = videos
		},
	},
})

// Reducer
export default slice.reducer

export function setSearch(value: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSearchValue(value))
		dispatchStore(getVideos())
	}
}

export function setType(value: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setType(value))
		dispatchStore(getVideos())
	}
}

export function getVideos() {
	return async (dispatch: Dispatch) => {
		const storage = store.getState().search
		dispatch(slice.actions.startLoading())
		if (storage.search) {
			dispatch(slice.actions.startLoading())
			try {
				if (storage.type_search === 'videos') {
					const response = await axios.get(`/api/video/public/search/${storage.search}`)
					dispatch(slice.actions.getVideosSuccess(response.data))
				} else {
					const response = await axios.get(`/api/stream/public/search/${storage.search}`)
					dispatch(slice.actions.getVideosSuccess(response.data))
				}
			} catch (error) {
				dispatch(slice.actions.hasError(error))
			}
		}
	}
}
