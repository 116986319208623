import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IStatementState, IStatusStatement } from 'src/@types/statement'
import { dispatch as dispatchStore } from '../store'
import axios from 'src/utils/axios'

const initialState: IStatementState = {
	isLoading: false,
	error: null,
	statement: null,
	statements: [],
}

const slice = createSlice({
	name: 'statement',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.statement = null
			state.statements = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getStatementSuccess(state, action) {
			state.isLoading = false
			const statement = action.payload
			state.statement = statement
		},

		getStatementsSuccess(state, action) {
			state.isLoading = false
			const statements = action.payload
			state.statements = statements
		},
	},
})

// Reducer
export default slice.reducer

export function getStatement(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/statement/${id}`)
			dispatch(slice.actions.getStatementSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getStatements() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/statement/`)
			dispatch(slice.actions.getStatementsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function sendStatement(video_id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`api/statement/${video_id}`)
			dispatch(slice.actions.getStatementSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateStatements(id: string, status: IStatusStatement) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.put(`api/statement/${id}`, { status: status })
			dispatchStore(getStatements())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
