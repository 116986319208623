import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import { ITag, ITagState } from 'src/@types/tag'
import axios from 'src/utils/axios'

const initialState: ITagState = {
	isLoading: false,
	error: null,
	tags: [],
}

const slice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.tags = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getTagsSuccess(state, action) {
			state.isLoading = false
			const tags = action.payload
			state.tags = tags
		},
	},
})

// Reducer
export default slice.reducer

export function getTags() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/tags/`)
			dispatch(slice.actions.getTagsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createTag(tag: ITag) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.post(`/api/tags/`, tag)
			return response.data
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export const getTagsThunk = createAsyncThunk(
	'tags/getTagsThunk',
	async (controller: AbortController, { rejectWithValue }) => {
		try {
			const response = await axios.get(`/api/tags/`, {
				signal: controller && controller.signal,
			})
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
