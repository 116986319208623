// routes
import { PATH_PAGE } from 'src/routes/paths'
// components
import SvgColor from 'src/components/svg-color'

// ----------------------------------------------------------------------

const icon = (name: string) => (
	<SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
	home: icon('ic_home'),
	library: icon('ic_library'),
	history: icon('ic_history'),
	subscriptions: icon('ic_subscriptions'),
	content: icon('ic_content'),
	liked: icon('ic_like'),
	broadcast: icon('ic_brodcast'),
	administrator: icon('ic_admin'),
	chat: icon('ic_chat'),
	blog: icon('ic_blog'),
	video: icon('ic_video')
}

const navConfig = [
	// GENERAL
	// ----------------------------------------------------------------------
	{
		subheader: '',
		isMini: true,
		items: [
			{ title: 'Главная', path: PATH_PAGE.home, icon: ICONS.home },
			{ title: 'Подкасты', path: PATH_PAGE.videos, icon: ICONS.video },
			{ title: 'Трансляции', path: PATH_PAGE.broadcast, icon: ICONS.broadcast },
			{ title: 'Блоги', path: PATH_PAGE.blogs.root, icon: ICONS.blog },
			{ title: 'Подписки', path: PATH_PAGE.subscriptions, icon: ICONS.subscriptions },
			// { title: 'Библиотека', path: PATH_PAGE.library, icon: ICONS.library },
		],
	},
	// AUTHENTICATED
	// ----------------------------------------------------------------------
	{
		isAuthenticated: true,
		isMini: false,
		subheader: '',
		items: [
			{ title: 'Ваши видео', path: PATH_PAGE.content.root, icon: ICONS.content },
			{ title: 'Чат', path: PATH_PAGE.chat.root, icon: ICONS.chat },
			// { title: 'Понравившиеся', path: PATH_PAGE.liked, icon: ICONS.liked },
			{ title: 'История', path: PATH_PAGE.history, icon: ICONS.history },
		],
	},
]

export default navConfig
