// i18n
import './locales/i18n'

// scroll bar
import 'simplebar/src/simplebar.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// editor
import 'react-quill/dist/quill.snow.css'

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
// routes
import Router from './routes'
// redux
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux/store'
// theme
import ThemeProvider from './theme'
// locales
import ThemeLocalization from './locales'
// components
import SnackbarProvider from './components/snackbar'
import ScrollToTop from './components/scroll-to-top'
import { MotionLazyContainer } from './components/animate'
import { ThemeSettings, SettingsProvider } from './components/settings'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from './auth/JwtContext'
// locale
import ruLocale from 'date-fns/locale/ru'
import { WSProvider } from './auth/WSProvider'

import axios from 'src/utils/axios'
import postscribe from 'postscribe'

// ----------------------------------------------------------------------

export default function App() {
	const getScript = async () => {
		let textScript: string
		try {
			const response = await axios.get(`api/metrics`)
			textScript = response.data.metric
		} catch (error) {
			textScript = ''
		}
		postscribe('#metrics_analytics', textScript)
	}

	getScript()
	return (
		<AuthProvider>
			<HelmetProvider>
				<ReduxProvider store={store}>
					<LocalizationProvider
						adapterLocale={ruLocale}
						dateAdapter={AdapterDateFns}
						localeText={{
							cancelButtonLabel: 'Отмена',
							okButtonLabel: 'Подтвердить',
							clearButtonLabel: 'Сбросить',
						}}
					>
						<SettingsProvider>
							<BrowserRouter>
								<ScrollToTop />
								<MotionLazyContainer>
									<ThemeProvider>
										<ThemeSettings>
											<ThemeLocalization>
												<SnackbarProvider>
													<WSProvider>
														<Router />
													</WSProvider>
												</SnackbarProvider>
											</ThemeLocalization>
										</ThemeSettings>
									</ThemeProvider>
								</MotionLazyContainer>
							</BrowserRouter>
						</SettingsProvider>
					</LocalizationProvider>
				</ReduxProvider>
			</HelmetProvider>
		</AuthProvider>
	)
}
