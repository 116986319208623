// @mui
import { Box, Drawer } from '@mui/material'
// hooks
import useResponsive from 'src/hooks/useResponsive'
// config
import { NAV } from 'src/config-global'
// components
import Scrollbar from 'src/components/scrollbar'
import { NavSectionVertical } from 'src/components/nav-section'
//
import navConfig from './config-navigation'
import { useSettingsContext } from '../../../components/settings'
import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

type Props = {
	openNav: boolean
	onCloseNav: VoidFunction
}

export default function NavVertical({ openNav, onCloseNav }: Props) {
	const isDesktop = useResponsive('up', 'lg')
	const { themeBackground } = useSettingsContext()

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				'& .simplebar-content': {
					height: 1,
					display: 'flex',
					flexDirection: 'column',
					zIndex: 10,
				},
			}}
		>
			<NavSectionVertical data={navConfig} />

			<Box sx={{ flexGrow: 1 }} />
		</Scrollbar>
	)

	return (
		<Box
			component='nav'
			sx={{
				flexShrink: { lg: 0 },
				width: { lg: NAV.W_MAIN },
			}}
		>
			{isDesktop ? (
				<Drawer
					open
					variant='persistent'
					PaperProps={{
						sx: {
							zIndex: 10,
							height: 'calc(100% - 56px)',
							bottom: 0,
							borderRight: 'unset',
							top: 'unset',
							width: NAV.W_MAIN,
							backgroundColor: themeBackground?.background
								? theme => alpha(theme.palette.background.paper, 0.8)
								: theme => theme.palette.background.paper,
						},
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {
							width: NAV.W_MAIN,
						},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	)
}
