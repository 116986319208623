// @mui
import { ruRU } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
	{
		label: 'Русский',
		value: 'ru',
		systemValue: ruRU,
		icon: '/assets/icons/flags/ic_flag_ru.svg'
	}
];

export const defaultLang = allLangs[0]; // English
