import { alpha, Theme } from '@mui/material/styles';
import { useSettingsContext } from "../../components/settings";

// ----------------------------------------------------------------------

export default function MuiCssBaseline(theme: Theme) {
    const { themeBackground } = useSettingsContext()

    if (themeBackground?.background)
        {
            return {
                MuiCssBaseline: {
                    styleOverrides: {
                        '& .main-layout': {
                            backgroundSize: 'cover',
                            backgroundImage: `url("data:image/png;base64,${themeBackground?.background}")`,
                            backgroundAttachment: 'fixed',
                        },
                        '& .translucent-background' : {
                            backgroundColor: themeBackground?.background ? alpha(theme.palette.background.paper, 0.6) : theme.palette.background.paper,
                        }
                    }
                }
            };
        }
}