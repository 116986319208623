import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IBlogState } from "src/@types/blog";
import { dispatch as dispatchStore, store } from "../store";
import { ITag } from "src/@types/tag";

const initialState: IBlogState = {
    isLoading: false,
    blog: null,
    blogs: [],
    error: null,
    search: '',
    pageNumber: 1,
    rowsPerPage: 10,
    count: 1,
    tag: null,
    tags: []
}
const slice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getBlogsSuccess(state, action) {
            state.isLoading = false
            const blogs = action.payload
            state.blogs = blogs
        },

        getBlogSuccess(state, action) {
            state.isLoading = false
            const blog = action.payload
            state.blog = blog
        },
        setCount(state, action) {
            const { count } = action.payload
            state.count = count
        },

        setRowsPerPage(state, action) {
            const rows = action.payload
            state.rowsPerPage = rows
        },

        setPageNumber(state, action) {
            const page = action.payload
            state.pageNumber = page
        },
        setSearch(state, action) {
            const search = action.payload
            state.search = search
        },
        setTag(state, action) {
            const tag = action.payload
            state.tag = tag
        },
        setTags(state, action) {
            const tags = action.payload
            state.tags = tags
        },
        clearFilter(state) {
            state.search = ''
            state.tags = []
            state.tag = null
        },
    },
})

// Reducer
export default slice.reducer

export function getBlogs() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const storage = store.getState().blog

            let params = new URLSearchParams()

            if (storage.pageNumber) {
                params.append('page', storage.pageNumber)
            }
            if (storage.rowsPerPage) {
                params.append('page_size', storage.rowsPerPage)
            }
            if (storage.tag) {
                params.append('tag', storage.tag.id)
            }
            if (storage.tags) {
                storage.tags.map((tag: ITag) => params.append('tags', tag.id))
            }
            const response = await axios.get(`/api/blogs/public/`, { params })
            dispatch(slice.actions.getBlogsSuccess(response.data.results))
            dispatch(slice.actions.setCount(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getBlog(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get(`/api/blogs/public/${id}`)
            dispatch(slice.actions.getBlogSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function setPageNumber(page: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setPageNumber(page))
        dispatchStore(getBlogs())
    }
}

export function setBlogTags(tags: ITag[]) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setTags(tags))
        dispatchStore(getBlogs())
    }
}

export function setBlogTag(tag: ITag | null) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.clearFilter())
        dispatch(slice.actions.setTag(tag))
        dispatchStore(getBlogs())
    }
}

export function clearFilter() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.clearFilter())
        dispatchStore(setPageNumber(1))
    }
}

export function setSearch(search: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setSearch(search))
        if (search.length > 2 || search.length === 0) dispatchStore(setPageNumber(1))
    }
}

export function setBlock(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`/api/blogs/private/${id}/block`)
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}