import { Box, Button, Tooltip, Typography } from '@mui/material'
import Iconify from 'src/components/iconify/Iconify'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'src/redux/store'
import { getVotes, updateVote } from 'src/redux/slices/vote'
import ConfirmDialog from 'src/components/confirm-dialog'
import { useAuthContext } from 'src/auth/useAuthContext'
import { PATH_AUTH } from 'src/routes/paths'
import { formatCount } from 'src/utils/removeBitDepth'

// ----------------------------------------------------------------------

export default function Vote() {
	const dispatch = useDispatch()
	const { user } = useAuthContext()

	const { uuid } = useParams()
	const { count, vote } = useSelector(state => state.vote)

	const [openConfirm, setOpenConfirm] = useState(false)
	const navigate = useNavigate()

	const handleCloseConfirm = () => {
		setOpenConfirm(false)
	}

	useEffect(() => {
		if (uuid) dispatch(getVotes(uuid))
	}, [uuid, dispatch])

	const setLike = (statusVote: string) => {
		if (user) {
			if (uuid) dispatch(updateVote(uuid, { vote: statusVote }))
		} else setOpenConfirm(true)
	}

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<Tooltip title={'Нравится'}>
					<Button
						sx={{
							border: '1px solid #ccc',
							borderLeft: 0,
							borderRadius: '40px 0 0 40px',
							px: 2,
						}}
						onClick={() => setLike('LIKE')}
					>
						{vote === 'LIKE' ? (
							<Iconify icon='mdi:thumb-up' />
						) : (
							<Iconify icon='mdi:like-outline' />
						)}
						<Typography variant='body2' fontSize={12} fontWeight={600} sx={{ pl: 1 }}>
							{formatCount(count)}
						</Typography>
					</Button>
				</Tooltip>
				<Tooltip title={'Не нравится'}>
					<Button
						sx={{
							border: '1px solid #ccc',
							borderLeft: 0,
							borderRadius: '0 40px 40px 0',
							px: 2,
						}}
						onClick={() => setLike('DISLIKE')}
					>
						{vote === 'DISLIKE' ? (
							<Iconify icon='mdi:thumb-down' />
						) : (
							<Iconify icon='mdi:dislike-outline' />
						)}
					</Button>
				</Tooltip>
			</Box>
			<ConfirmDialog
				open={openConfirm}
				onClose={handleCloseConfirm}
				title='Авторизация'
				content={<>Войдите в аккаунт, чтобы поставить отметку?</>}
				action={
					<Button
						variant='contained'
						onClick={() => {
							handleCloseConfirm()
							navigate(PATH_AUTH.login, { replace: true })
						}}
					>
						Войти
					</Button>
				}
			/>
		</>
	)
}
