import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
// slices
import videoReducer from './slices/video'
import topVideoReducer from './slices/top_video'
import privateVideoReducer from './slices/private_video'
import uploadVideoReducer from './slices/upload'
import streamReducer from './slices/stream'
import broadcastReducer from './slices/broadcast'
import tagsReducer from './slices/tag'
import discussionReducer from './slices/discussion'
import paidContents from './slices/paidContents'
import historyReducer from './slices/history'
import searchReducer from './slices/search'
import statementReducer from './slices/statement'
import voteReducer from './slices/vote'
import backgroundReducer from './slices/background'
import removeUserReducer from './slices/removeUser'
import metricsReducer from './slices/metrics'
import advertisingReducer from './slices/advertising'
import blogReducer from './slices/blog'
import topBlogReducer from './slices/top_blog'
import privateBlogReducer from './slices/private_blog'
import supportReducer from './slices/support'
import accountReducer from './slices/account'
import paymentReducer from './slices/payment'
import videoBlockReducer from './slices/videoBlock'
import streamBlockReducer from './slices/streamBlock'
import blogBlockReducer from './slices/blogBlock'
import playlist from './slices/playlist'
import channel from './slices/channel'
// ----------------------------------------------------------------------

export const rootPersistConfig = {
	key: 'root',
	storage,
	keyPrefix: 'redux-',
	whitelist: [],
}

const rootReducer = combineReducers({
	video: videoReducer,
	top_video: topVideoReducer,
	private_video: privateVideoReducer,
	upload: uploadVideoReducer,
	stream: streamReducer,
	broadcast: broadcastReducer,
	tags: tagsReducer,
	discussion: discussionReducer,
	paid_contents: paidContents,
	history: historyReducer,
	search: searchReducer,
	statement: statementReducer,
	vote: voteReducer,
	background: backgroundReducer,
	removeUser: removeUserReducer,
	metrics: metricsReducer,
	advertising: advertisingReducer,
	blog: blogReducer,
	private_blog: privateBlogReducer,
	top_blogs: topBlogReducer,
	support: supportReducer,
	account: accountReducer,
	payments: paymentReducer,
	videoBlock: videoBlockReducer,
	streamBlock: streamBlockReducer,
	blogBlock: blogBlockReducer,
	playlist: playlist,
	channel: channel
})

export default rootReducer
