import { Dispatch, createSlice } from '@reduxjs/toolkit'
import { PaidContent, PaidContentsSliceState } from 'src/@types/paidContents'
import { store } from '../store'
import axios from 'src/utils/axios'

const initialState: PaidContentsSliceState = {
	isLoading: false,
	error: null,
	paidContents: [],
	paidContent: null,
	undistributedVideos: [],
	userPaidContents: [],
}

const slice = createSlice({
	name: 'paidContents',
	initialState,
	reducers: {
		startLoading(state) {
			state.isLoading = true
		},

		stopLoading(state) {
			state.isLoading = false
		},

		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getPaidContentsSuccess(state, action) {
			state.paidContents = action.payload
			state.isLoading = false
		},

		getPaidContentSuccess(state, action) {
			state.paidContent = action.payload
			state.isLoading = false
		},

		getUndistributedVideosSuccess(state, action) {
			state.undistributedVideos = action.payload
			state.isLoading = false
		},

		getUserPaidContentsSuccess(state, action) {
			state.userPaidContents = action.payload
			state.isLoading = false
		},
	},
})

export default slice.reducer

export function getPaidContents() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('api/playlist/paid_contents/')
			dispatch(slice.actions.getPaidContentsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPaidContent(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/playlist/paid_contents/${id}`)
			dispatch(slice.actions.getPaidContentSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getPaidContentsThunk() {
	return async (_: Dispatch) => {
		try {
			const response = await axios.get('api/playlist/paid_contents/')
			return response.data
		} catch {
			return []
		}
	}
}

export function getUndistributedVideos() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get('api/playlist/free_commercial')
			dispatch(slice.actions.getUndistributedVideosSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getUserPaidContents() {
	return async (dispatch: Dispatch) => {
		try {
			const response = await axios.get('api/playlist/paid_contents_user/')
			dispatch(slice.actions.getUserPaidContentsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function addPaidContents(data: Omit<PaidContent, 'id'>) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post('api/playlist/paid_contents/', { ...data })
			store.dispatch(getPaidContents())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function addItemToPaidContent(paidContentId: string, itemId: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(`api/playlist/paid_contents/${paidContentId}/add`, { id: itemId })
			store.dispatch(getUndistributedVideos())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
