import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { ITopVideoState } from 'src/@types/video'
import { store } from '../store'

const initialState: ITopVideoState = {
	isLoading: false,
	error: null,
    count: 8,
	videos: [],
}

const slice = createSlice({
	name: 'top_video',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.videos = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getVideosSuccess(state, action) {
			state.isLoading = false
			const videos = action.payload
			state.videos = videos
		},
	},
})

// Reducer
export default slice.reducer

export function getTopVideo() {
	return async (dispatch: Dispatch) => {
        const storage = store.getState().top_video
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/video/public/top/${storage.count}`)
			dispatch(slice.actions.getVideosSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
