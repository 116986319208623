import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import SnackbarUtils from "../../utils/snackbar";
import { IBlog, IPrivateBlogState } from "../../@types/blog";
import { dispatch as dispatchStore, store } from "../store";
import moment from "moment-timezone";

let abortController: AbortController

const initialState: IPrivateBlogState = {
    isLoading: false,
    blog: null,
    blogs: [],
    error: null,
    search: '',
    count: 0,
    rowsPerPage: 10,
    pageNumber: 1,
    order: 'desc',
    orderBy: 'created_at',
    filterDateStart: null,
    filterDateEnd: null,
    is_archive: false,
}
const slice = createSlice({
    name: 'private_blog',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true

        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getBlogsSuccess(state, action) {
            state.isLoading = false
            const blogs = action.payload
            state.blogs = blogs
        },

        getBlogSuccess(state, action) {
            state.isLoading = false
            const blog = action.payload
            state.blog = blog
        },
        setCount(state, action) {
            const { count } = action.payload
            state.count = count
        },

        setRowsPerPage(state, action) {
            const rows = action.payload
            state.rowsPerPage = rows
        },

        setPageNumber(state, action) {
            const page = action.payload
            state.pageNumber = page
        },
        setSearch(state, action) {
            const search = action.payload
            state.search = search
        },
        setFilterDateStart(state, action) {
            const filterDateStart = action.payload
            state.filterDateStart = filterDateStart
        },

        setFilterDateEnd(state, action) {
            const filterDateEnd = action.payload
            state.filterDateEnd = filterDateEnd
        },
        setArchive(state, action) {
            const is_archive = action.payload
            state.is_archive = is_archive
        },
    },
})

// Reducer
export default slice.reducer

export function getBlogs() {
    return async (dispatch: Dispatch) => {

        dispatch(slice.actions.startLoading())
        try {
            const storage = store.getState().private_blog

            if (abortController) {
                abortController.abort() // Tell the browser to abort request
            }

            abortController = new AbortController()

            let params = new URLSearchParams()
            if (storage.pageNumber) {
                params.append('page', storage.pageNumber)
            }
            if (storage.rowsPerPage) {
                params.append('page_size', storage.rowsPerPage)
            }
            if (storage.search) {
                params.append('search', storage.search)
            }

            if (storage.filterDateStart) {
                params.append('date_start', moment(storage.filterDateStart).format('YYYY-MM-DD 00:00:00Z'))
            }

            if (storage.filterDateEnd) {
                params.append('date_end', moment(storage.filterDateEnd).format('YYYY-MM-DD 23:59:59Z'))
            }
            if (storage.is_archive) {
                params.append('is_archive', storage.is_archive)
            }
            const response = await axios.get(`/api/blogs/private/list`, {
                params,
                signal: abortController && abortController.signal,
            })
            if (storage.pageNumber) {
                dispatch(slice.actions.getBlogsSuccess(response.data.results))
                dispatch(slice.actions.setCount(response.data))
            }
            else
                dispatch(slice.actions.getBlogsSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getBlog(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get(`/api/blogs/private/${id}`)
            dispatch(slice.actions.getBlogSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function updateBlog(data: IBlog) {
    const { id } = data
    const blog = {
        name: data.name,
        description: data.description,
        text: data.text,
        blog_cover: data.blog_cover,
        is_comments: data.is_comments,
        is_published: data.is_published,
        tags_ids: data.tags_ids
    }
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.put(`/api/blogs/private/${id}`, blog)
            dispatch(slice.actions.getBlogSuccess(response.data))
            SnackbarUtils.success('Блог обновлен')
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function createBlog(blog: IBlog) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.post(`/api/blogs/private/`, blog)
            SnackbarUtils.success('Блог создан')
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function setRowsPerPage(rows: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setRowsPerPage(rows))
        dispatchStore(setPageNumber(1))
    }
}

export function setPageNumber(page: number) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setPageNumber(page))
        dispatchStore(getBlogs())
    }
}

export function setSearch(search: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setSearch(search))
        if (search.length > 2 || search.length === 0) dispatchStore(setPageNumber(1))
    }
}

export function setFilterDateStart(date: Date | null) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setFilterDateStart(date))
        dispatchStore(setPageNumber(1))
    }
}

// ----------------------------------------------------------------------
export function setFilterDateEnd(date: Date | null) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setFilterDateEnd(date))
        dispatchStore(setPageNumber(1))
    }
}

export function filterReset() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setFilterDateEnd(null))
        dispatch(slice.actions.setFilterDateStart(null))
        dispatch(slice.actions.setSearch(''))
        dispatchStore(setPageNumber(1))
    }
}

export function setArchive(archive: boolean) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.setArchive(archive))
        dispatchStore(setPageNumber(1))
    }
}

export function deleteBlog(uuid: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.delete(`api/blogs/private/${uuid}`)
            dispatchStore(setPageNumber(1))
            SnackbarUtils.success('Удалено')
        } catch (error) {
            console.error(error)
            SnackbarUtils.error(`Ошибка при удалении`)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function deleteBlogs(id: string[]) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.delete(`api/blogs/private`, { data: id } )
            dispatchStore(setPageNumber(1))
            SnackbarUtils.success('Удалено')
        } catch (error) {
            console.error(error)
            SnackbarUtils.error(`Ошибка при удалении`)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function toArchiveBlog(blog: IBlog) {
    const { id, is_archive } = blog
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.put(`api/blogs/private/${id}`, { is_archive: !is_archive, is_published: false })
            dispatchStore(setPageNumber(1))
            SnackbarUtils.success('Отправлен в архив')
        } catch (error) {
            SnackbarUtils.error(`Ошибка при отправке в архив`)
            dispatch(slice.actions.hasError(error))
        }
    }
}