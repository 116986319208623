import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IPrivateVideoState } from 'src/@types/video'
import { dispatch as dispatchStore, store } from '../store'
import SnackbarUtils from 'src/utils/snackbar'
import moment from 'moment-timezone'
import { serialize } from 'object-to-formdata'
import { ISubtitle } from 'src/@types/subtitles'

let abortController: AbortController

const initialState: IPrivateVideoState = {
	isLoading: false,
	error: null,
	videos: [],
	video: null,
	subtitles: [],
	search: '',
	count: 0,
	rowsPerPage: 10,
	pageNumber: 1,
	order: 'desc',
	orderBy: 'created_at',
	filterDateStart: null,
	filterDateEnd: null,
}

const slice = createSlice({
	name: 'private_video',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.videos = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getVideosSuccess(state, action) {
			state.isLoading = false
			const videos = action.payload
			state.videos = videos
		},

		getVideoSuccess(state, action) {
			state.isLoading = false
			const video = action.payload
			state.video = video
		},

		setSubtitles(state, action) {
			state.isLoading = false
			const subtitles = action.payload
			state.subtitles = subtitles
		},
		setCount(state, action) {
			const count = action.payload
			state.count = count
		},

		setRowsPerPage(state, action) {
			const rows = action.payload
			state.rowsPerPage = rows
		},

		setPageNumber(state, action) {
			const page = action.payload
			state.pageNumber = page
		},
		setSearch(state, action) {
			const search = action.payload
			state.search = search
		},
		setFilterDateStart(state, action) {
			const filterDateStart = action.payload
			state.filterDateStart = filterDateStart
		},

		setFilterDateEnd(state, action) {
			const filterDateEnd = action.payload
			state.filterDateEnd = filterDateEnd
		},
	},
})

// Reducer
export default slice.reducer

export function getVideos() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const storage = store.getState().private_video

			if (abortController) {
				abortController.abort() // Tell the browser to abort request
			}

			abortController = new AbortController()

			let params = new URLSearchParams()

			if (storage.search) {
				params.append('search', storage.search)
			}

			if (storage.filterDateStart) {
				params.append('date_start', moment(storage.filterDateStart).format('YYYY-MM-DD 00:00:00Z'))
			}

			if (storage.filterDateEnd) {
				params.append('date_end', moment(storage.filterDateEnd).format('YYYY-MM-DD 23:59:59Z'))
			}

			const response = await axios.get(`api/video/private/list`, {
				params,
				signal: abortController && abortController.signal,
			})
			dispatch(slice.actions.getVideosSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getVideo(uuid: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/video/private/item/${uuid}`)
			dispatch(slice.actions.getVideoSuccess(response.data))
			dispatch(slice.actions.setSubtitles(response.data?.subtitles ?? []))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setRowsPerPage(rows: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setRowsPerPage(rows))
		dispatchStore(setPageNumber(1))
	}
}

export function setPageNumber(page: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setPageNumber(page))
		dispatchStore(getVideos())
	}
}

export function setSearch(search: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSearch(search))
		if (search.length > 2 || search.length === 0) dispatchStore(setPageNumber(1))
	}
}

export function setFilterDateStart(date: Date | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setFilterDateStart(date))
		dispatchStore(setPageNumber(1))
	}
}

// ----------------------------------------------------------------------
export function setFilterDateEnd(date: Date | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setFilterDateEnd(date))
		dispatchStore(setPageNumber(1))
	}
}

export function filterReset() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setFilterDateEnd(null))
		dispatch(slice.actions.setFilterDateStart(null))
		dispatch(slice.actions.setSearch(''))
		dispatchStore(setPageNumber(1))
	}
}

export function deleteVideo(uuid: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`api/video/private/item/${uuid}`)
			dispatchStore(setPageNumber(1))
			SnackbarUtils.success('Удалено')
		} catch (error) {
			console.error(error)
			SnackbarUtils.error(`Ошибка при удалении`)
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function deleteVideos(uuids: string[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`api/video/private/item`, { data: uuids } )
			dispatchStore(setPageNumber(1))
			SnackbarUtils.success('Удалено')
		} catch (error) {
			console.error(error)
			SnackbarUtils.error(`Ошибка при удалении`)
			dispatch(slice.actions.hasError(error))
		}
	}
}

type IFormValuesProps = Omit<ISubtitle, 'file'>

interface FormValuesProps extends IFormValuesProps {
	file?: File | string | null
}

export function addSubtitles(
	video_id: string,
	data: FormValuesProps | null,
	subtitleFile: File | null
) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(slice.actions.startLoading())
			const formData = serialize({ ...data, file: subtitleFile })
			const response = await axios.post(`api/video/private/item/${video_id}/subtitle`, formData)
			dispatch(slice.actions.setSubtitles(response.data))
		} catch (err) {
			console.log(err)
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function updateSubtitles(data: FormValuesProps | null, subtitleFile: File | null) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(slice.actions.startLoading())
			delete data!.file
			const formData = serialize({ ...data, ...(subtitleFile && { file: subtitleFile }) })
			const response = await axios.post(`api/subtitles/${data!.id}`, formData)
			dispatch(slice.actions.setSubtitles(response.data))
		} catch (err) {
			console.log(err)
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function deleteSubtitles(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(slice.actions.startLoading())
			const response = await axios.delete(`api/subtitles/${id}`)
			dispatch(slice.actions.setSubtitles(response.data))
			SnackbarUtils.success('Субтитры удалены')
		} catch (err) {
			console.log(err)
			dispatch(slice.actions.hasError(err))
		}
	}
}
