import { createSlice, Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { IAdvertising, IAdvertisingState } from 'src/@types/advertising'
import axios from 'src/utils/axios'
import _ from 'lodash'

const initialState: IAdvertisingState = {
	isLoading: false,
	error: null,
	advertising: null,
	advertisings: [],
}

const slice = createSlice({
	name: 'advertising',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.advertising = null
			state.advertisings = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getAdvertisings(state, action) {
			state.isLoading = false
			const advertisings = action.payload
			state.advertisings = advertisings
		},

		getAdvertising(state, action) {
			state.isLoading = false
			const advertising = action.payload
			state.advertising = advertising
		},
	},
})

// Reducer
export default slice.reducer

export function getAdvertisings() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/advertising`)
			dispatch(slice.actions.getAdvertisings(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getAdvertising(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/advertising/${id}`)
			dispatch(slice.actions.getAdvertising(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createAdvertising(data: IAdvertising) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(`api/advertising/`, _.omit(data, ['id']))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function updateAdvertising(data: IAdvertising) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.put(`api/advertising/${data.id}`, data)
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export const createAdvertisingThunk = createAsyncThunk(
	'advertising',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(`api/advertising/ticker`)
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const getHttpAdvertisingThunk = createAsyncThunk(
	'advertisingHTML',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(`api/advertising/block_html`)
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
