// @mui
import { List, Stack, Divider } from '@mui/material'
// locales
import { useLocales } from '../../../locales'
//
import { NavSectionProps } from '../types'
import { StyledSubheader } from './styles'
import NavList from './NavList'
import { useAuthContext } from 'src/auth/useAuthContext'

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
	const { translate } = useLocales()
	const { isAuthenticated } = useAuthContext()

	return (
		<Stack sx={sx} {...other}>
			{data.map(group => {
				const key = group.subheader || group.items[0].title

				if (group.isAuthenticated) {
					if (isAuthenticated) {
						return (
							<List key={key} disablePadding sx={{ px: 1 }}>
								<Divider sx={{ borderStyle: 'dashed' }} />
								{group.subheader && (
									<StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
								)}

								{group.items.map(list => (
									<NavList
										key={list.title + list.path}
										data={list}
										depth={1}
										hasChild={!!list.children}
									/>
								))}
							</List>
						)
					} else {
						return null
					}
				}

				return (
					<List key={key} disablePadding sx={{ px: 1 }}>
						{group.subheader && (
							<StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
						)}

						{group.items.map(list => (
							<NavList
								key={list.title + list.path}
								data={list}
								depth={1}
								hasChild={!!list.children}
							/>
						))}
					</List>
				)
			})}
		</Stack>
	)
}
