import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IPaymentUserState, IPay } from 'src/@types/payment'
import axios from 'src/utils/axios'
import { store } from '../store'

const initialState: IPaymentUserState = {
	isLoading: false,
	error: null,
	payments: [],
}

const slice = createSlice({
	name: 'payments',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.payments = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		setPaymentUser(state, action) {
			state.isLoading = false
			const payments = action.payload
			state.payments = payments
		},
	},
})

// Reducer
export default slice.reducer

export function getPaymentUser() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/payment/`)
			dispatch(slice.actions.setPaymentUser(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function newPaymentUser(data: IPay) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			let resp = await axios.post(
				`/api/payment/`,
				{ username: data.username, amount: data.amount, description: data.description }
			)
			store.dispatch(getPaymentUser())
			return resp
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function acceptPaymentUser(code: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(
				`/api/payment/confirm`,
				{ code: code }
			)
			store.dispatch(getPaymentUser())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function refundPaymentUser(code: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(
				`/api/payment/refund`,
				{ code: code }
			)
			store.dispatch(getPaymentUser())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}