import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useAuthContext } from 'src/auth/useAuthContext'
import ConfirmDialog from 'src/components/confirm-dialog'
import Iconify from 'src/components/iconify'
import { getStatement, sendStatement } from 'src/redux/slices/statement'
import { useDispatch, useSelector } from 'src/redux/store'
import { PATH_AUTH } from 'src/routes/paths'

type IProps = {
	id: string
	canView: boolean
}

const SubscribeVideo: FC<IProps> = ({ id, canView }) => {
	const { isAuthenticated } = useAuthContext()

	const navigate = useNavigate()

	const dispatch = useDispatch()

	const { isLoading, statement } = useSelector(state => state.statement)

	const [openConfirm, setOpenConfirm] = useState<boolean>(false)

	const handleOpenConfirm = () => {
		setOpenConfirm(true)
	}

	const handleCloseConfirm = () => {
		setOpenConfirm(false)
	}

	const handleSendStatement = () => {
		if (isAuthenticated) {
			dispatch(sendStatement(id))
		}
		handleCloseConfirm()
	}

	useEffect(() => {
		if (isAuthenticated && !canView) {
			dispatch(getStatement(id))
		}
	}, [id, dispatch, isAuthenticated, canView])

	return (
		<>
			{!isLoading && statement ? (
				<>
					{statement.status === 'pending' && (
						<LoadingButton disabled variant='contained'>
							<Iconify icon='eos-icons:loading' />
							<Typography variant='body2' sx={{ pl: 1 }}>
								В обработке
							</Typography>
						</LoadingButton>
					)}
					{statement.status === 'decline' && (
						<LoadingButton variant='contained' disabled>
							Отказано в доступе
						</LoadingButton>
					)}
				</>
			) : (
				<>
					{!isLoading && (
						<LoadingButton
							variant='contained'
							onClick={() => {
								if (isAuthenticated) {
									handleOpenConfirm()
								} else {
									navigate(PATH_AUTH.login)
								}
							}}
						>
							Подписаться
						</LoadingButton>
					)}
				</>
			)}
			<ConfirmDialog
				open={openConfirm}
				onClose={handleCloseConfirm}
				title='Подписаться'
				content={`Вы уверены, что хотите оставить заявку на подписку?`}
				action={
					<LoadingButton variant='contained' loading={isLoading} onClick={handleSendStatement}>
						Подписаться
					</LoadingButton>
				}
			/>
		</>
	)
}

export default SubscribeVideo
