import { forwardRef, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Box, Link, BoxProps } from '@mui/material'
// components
// import SvgColor from 'src/components/svg-color/SvgColor'
import Img from 'src/components/image/Image'

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
	disabledLink?: boolean
	children?: React.ReactNode
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
	({ disabledLink = false, sx, children, ...other }, ref) => {
		const [srcImage, setScrImage] = useState<string | null>(null)

		const checkImage = (path: string) =>
			new Promise((resolve, reject) => {
				const img = new Image()
				img.onload = () => resolve(path)
				img.onerror = () => reject()

				img.src = path
			})

		useEffect(() => {
			const fetchImage = async (path: string) => {
				try {
					const img = await checkImage(path)
					if (img) {
						setScrImage(img as string)
					}
				} catch (e) {
					console.log('Logo not found')
				}
			}
			fetchImage('/api/logo/')
		}, [])

		const logo = (
			<Box
				ref={ref}
				component='div'
				sx={{
					width: 40,
					height: 40,
					display: 'inline-flex',
					...sx,
				}}
				{...other}
			>
				<Img
					disabledEffect
					src={srcImage as string}
					sx={{
						'& img': {
							objectFit: 'contain !important',
						},
					}}
				/>
			</Box>
		)

		if (disabledLink && srcImage) {
			return logo
		}

		if (children) {
			return (
				<Link
					to='/'
					component={RouterLink}
					sx={{
						display: 'contents',
						color: 'inherit',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
						{srcImage && logo}
						{children}
					</Box>
				</Link>
			)
		}

		return (
			<Link component={RouterLink} to='/' sx={{ display: 'contents' }}>
				{srcImage && logo}
			</Link>
		)
	}
)

export default Logo
