import { m } from 'framer-motion'
// @mui
import { Container, Typography } from '@mui/material'
// components
import { MotionContainer, varBounce } from '../components/animate'
// assets
import { ForbiddenIllustration } from '../assets/illustrations'
//
import { useAuthContext } from './useAuthContext'

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
	hasContent?: boolean
	roles?: string[]
	children: React.ReactNode
}

export default function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProp) {
	// Logic here to get current user role
	const { isInitialized, user } = useAuthContext()

	// const currentRole = 'user';
	const currentRole = user?.is_staff ? 'admin' : 'user' // admin;

	if (!isInitialized) return null

	if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
		return hasContent ? (
			<Container component={MotionContainer} sx={{ textAlign: 'center', pt: 6 }}>
				<m.div variants={varBounce().in}>
					<Typography variant='h3' paragraph>
						Доступ запрещен
					</Typography>
				</m.div>

				<m.div variants={varBounce().in}>
					<Typography sx={{ color: 'text.secondary' }}>
						Вы не имеете доступа к этой странице
					</Typography>
				</m.div>

				<m.div variants={varBounce().in}>
					<ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
				</m.div>
			</Container>
		) : null
	}

	return <> {children} </>
}
