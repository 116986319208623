/* eslint-disable import/no-duplicates */
import { format, getTime, formatDistanceToNow } from 'date-fns'
import moment from 'moment-timezone'
import ruLocale from 'date-fns/locale/ru'

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null

export function fDateName(date: InputValue, newFormat?: string) {
	const fm = newFormat || 'dd MMMM yyyy'

	return date ? format(new Date(date), fm, { locale: ruLocale }) : ''
}

export function fDate(date: InputValue, newFormat?: string) {
	const fm = newFormat || 'dd.MM.yyyy'

	return date ? format(moment(date, 'DD.MM.YYYY HH:mm').toDate(), fm, { locale: ruLocale }) : ''
}

export function fDateTime(date: InputValue, newFormat?: string) {
	const fm = newFormat || 'dd.MM.yyyy p'

	return date ? format(new Date(date), fm, { locale: ruLocale }) : ''
}

export function fTimestamp(date: InputValue) {
	return date ? getTime(new Date(date)) : ''
}

export function fToNow(date: InputValue) {
	return date
		? formatDistanceToNow(new Date(date), {
				addSuffix: true,
				locale: ruLocale,
		  })
		: ''
}

export function fDateTimeIn(date: InputValue) {
	return date ? format(new Date(date), 'dd.MM.yyyy в hh:mm', { locale: ruLocale }) : ''
}

export function createDateByString(date: string | null) {
	return date ? moment(date, 'DD.MM.YYYY HH:mm').toDate() : null
}
