import { createSlice, Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { IRemoveUserState } from 'src/@types/removeUser'
import axios from 'src/utils/axios'

const initialState: IRemoveUserState = {
	isLoading: false,
	error: null,
	usersR: [],
}

const slice = createSlice({
	name: 'removeUser',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.usersR = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getRemoveUsers(state, action) {
			state.isLoading = false
			const users = action.payload
			state.usersR = users
		},
	},
})

// Reducer
export default slice.reducer

export function getRemoveUsers() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/account/remove_user`)
			dispatch(slice.actions.getRemoveUsers(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export const createRemoveUserThunk = createAsyncThunk(
	'account/removeUserPost',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.post(`/api/account/remove_user`)
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export function updateRemoveUser(id: string, status: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.put(`/api/account/remove_user/${id}`, { status: status })
			dispatch(slice.actions.getRemoveUsers(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
