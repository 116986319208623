import React, { FC, useCallback } from 'react'
import ReactPlayer from 'react-player'
import { IVideo } from 'src/@types/video'

import Iconify from 'src/components/iconify'
import { Stack, Typography } from '@mui/material'
import { HOST_API_KEY } from 'src/config-global'
import SubscribeVideo from './SubscribeVideo'

type IProps = {
	video: IVideo | null
}

const CustomReactPlayer: FC<IProps> = ({ video }) => {
	const getSrc = useCallback(() => {
		if (video) {
			return `${HOST_API_KEY}/api/video/public/blob/${video.id}`
		}
		return undefined
	}, [video])

	return (
		<>
			{video?.can_view ?? true ? (
				<Stack sx={{ minWidth: 300, maxHeight: video?.orientation === 'portrait' ? '25%' : '40%' }}>
					<ReactPlayer
						controls
						playing
						url={getSrc()}
						width='100%'
						height='100%'
						config={{
							file: {
								tracks: video?.subtitles ?? [],
							},
						}}
					/>
				</Stack>
			) : (
				<Stack
					sx={{ minHeight: '500px', background: 'black' }}
					alignItems={'center'}
					justifyContent={'center'}
					gap={2}
				>
					<Iconify icon='majesticons:rubel-circle-line' width={150} height={150} color={'white'} />
					<Typography variant={'body2'} fontSize={20} fontWeight={700} sx={{ color: 'white' }}>
						у Вас нет подписки на данное видео
					</Typography>
					<SubscribeVideo id={video!.id} canView={video!.can_view} />
				</Stack>
			)}
		</>
	)
}

export default CustomReactPlayer
