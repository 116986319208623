// @mui
import { Box, List } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { IDiscussion } from 'src/@types/discussion'
import { getBlogDiscussions, getDiscussions } from 'src/redux/slices/discussion'
import { useDispatch, useSelector } from 'src/redux/store'
//
import CommentItem from './CommentItem'

type Props = {
	Item: string
}

export default function CommentList({ Item } : Props) {
	const dispatch = useDispatch()

	const { uuid } = useParams()

	const { comments } = useSelector(state => state.discussion)

	useEffect(() => {
		if (uuid)
			if (Item.includes('blog'))
				dispatch(getBlogDiscussions(uuid))
			else if(Item.includes('video'))
				dispatch(getDiscussions(uuid))
	}, [uuid, dispatch, Item])

	return (
		<List disablePadding>
			{comments.map((comment: IDiscussion) => {
				const { id, reply_to, text, created_date, created_by, can_block } = comment

				const hasReply = reply_to && (reply_to as IDiscussion[])?.length > 0

				return (
					<Box key={id}>
						<CommentItem
							Item={Item}
							id={id}
							message={text}
							created_date={created_date}
							created_by={created_by}
							canBlock={can_block ? can_block : false}
						/>
						{hasReply &&
							(reply_to as IDiscussion[])?.map((reply: IDiscussion, idx: number) => (
								<CommentItem
									Item={Item}
									key={`comment_item_${idx}`}
									id={reply.id}
									message={reply.text}
									created_date={reply.created_date}
									created_by={reply.created_by}
									canBlock={reply.can_block ? reply.can_block : false}
									hasReply
								/>
							))}
					</Box>
				)
			})}
		</List>
	)
}
