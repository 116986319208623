import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { createFilterOptions, DialogContent, MenuItem, Stack } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormProvider from '../hook-form/FormProvider'
import { useDispatch, useSelector } from 'src/redux/store'
import { RHFSelect, RHFSwitch, RHFTextField } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import { updateVideo } from 'src/redux/slices/upload'
import { IVideo } from 'src/@types/video'
import { getVideos } from 'src/redux/slices/private_video'
import { ITag, ITagInput } from 'src/@types/tag'
import CustomAutocomplete from 'src/components/autocomplete/CustomAutocomplete'
import { createTag, getTagsThunk } from 'src/redux/slices/tag'
import Editor from 'src/components/editor'

enum ORIENTATION {
	landscape = 'Альбомная',
	portrait = 'Портрет',
}

interface FormValuesProps {
	id: string
	name: string
	description: string
	is_published: boolean
	orientation: 'landscape' | 'portrait'
	tags_ids: ITag[]
	is_commercial: boolean
	is_comments: boolean
}

export default function DialogUpdateVideo({ handleClose }: { handleClose: VoidFunction }) {
	const dispatch = useDispatch()

	const { isLoading, video } = useSelector(state => state.upload)

	const UpdateSchema = Yup.object().shape({
		name: Yup.string().nullable().required('Заполните Наименование'),
	})

	const defaultValues = useMemo(
		() => ({
			id: video?.id || '',
			name: video?.name || '',
			description: video?.description || '',
			is_published: video?.is_published || false,
			orientation: video?.orientation || 'landscape',
			tags_ids: video?.tags_ids || [],
			is_commercial: video?.is_commercial || false,
			is_comments: video?.is_comments || true,
		}),
		[video]
	)

	const filter = createFilterOptions<ITag>();
	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(UpdateSchema),
		defaultValues,
	})

	const {
		handleSubmit,
		control,
		formState: { isSubmitting }, //isValid
	} = methods

	const handleSave = async (data: FormValuesProps) => {
		try {
			await dispatch(updateVideo(data as IVideo))
			await dispatch(getVideos())
			handleClose()
		} catch (error) {
			console.error(error)
		}
	}
	const onLoadOptions = async (controller: AbortController) => {
		try {
			const resultAction = await dispatch(getTagsThunk(controller))
			if (getTagsThunk.fulfilled.match(resultAction)) {
				return resultAction.payload
			} else {
				return []
			}
		} catch (err) {
			return []
		}
	}

	return (
		<DialogContent sx={{ overflow: 'unset', pb: 3 }}>
			<FormProvider methods={methods}>
				<Stack direction='column' spacing={2}>
					<RHFTextField name='name' label='Наименование' />
					<Controller
						control={control}
						name='description'
						render={({ field }) => (
							<Editor
								id='video-editor'
								value={field.value}
								simple
								onChange={value => field.onChange(value)}
								placeholder={'Введите описание'}
							/>
						)}
					/>
					<RHFSelect
						fullWidth
						disabled
						name='orientation'
						label='Ориентация'
						InputLabelProps={{ shrink: true }}
						SelectProps={{ native: false }}
					>
						{Object.entries(ORIENTATION).map(([key, value], idx) => (
							<MenuItem
								key={`type_document_${idx}`}
								value={key}
								sx={{
									mx: 1,
									my: 0.5,
									borderRadius: 0.75,
									typography: 'body2',
									textTransform: 'capitalize',
									'&:first-of-type': { mt: 0 },
									'&:last-of-type': { mb: 0 },
								}}
							>
								{value}
							</MenuItem>
						))}
					</RHFSelect>
					<Controller
						control={control}
						name='tags_ids'
						render={({ field }) => (
							<CustomAutocomplete<ITag>
								multiple
								value={field.value}
								onLoadOptions={onLoadOptions}
								onChange={(_, v) => {
									if((v as ITagInput[]).slice(-1)[0] && (v as ITagInput[]).slice(-1)[0].inputValue){
										dispatch(createTag({ name: (v as ITagInput[]).slice(-1)[0].inputValue } as ITag))
											.then((result)=> field.onChange([...field.value, result]))
									}
									else
										field.onChange(v as ITag[])
								}}
								filterOptions={(options, params, loading) => {
									const filtered = filter(options, params);

									const { inputValue } = params;
									// Suggest the creation of a new value
									const isExisting = options.some((option) => inputValue === option.name);
									if (inputValue !== '' && !isExisting && !loading) {
										filtered.push({
											inputValue: inputValue,
											name: `Добавить тег "${inputValue}"`,
										} as ITagInput);
									}
									return filtered;
								}}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								label='Теги'
								placeholder='Выберите теги'
								getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
							/>
						)}
					/>
					<RHFSwitch name='is_published' label='Опубликовать' />
					<RHFSwitch name='is_commercial' label='Коммерческое' />
					<RHFSwitch name='is_comments' label='Разрешить комментарии' />
				</Stack>
				<Stack direction={'row'} justifyContent={'end'}>
					<LoadingButton
						size='medium'
						variant='contained'
						loading={isLoading && isSubmitting}
						onClick={handleSubmit(handleSave)}
					>
						Обновить
					</LoadingButton>
				</Stack>
			</FormProvider>
		</DialogContent>
	)
}
