import React, { Dispatch, FC, SetStateAction, forwardRef, useState } from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Slide,
	Stack,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import Iconify from 'src/components/iconify/Iconify'
import { LoadingButton } from '@mui/lab'
import { useAuthContext } from 'src/auth/useAuthContext'
import { setGroupAccountThunk } from 'src/redux/slices/account'
import { useDispatch } from 'src/redux/store'
import { useSnackbar } from 'src/components/snackbar'
import CustomAutocomplete from 'src/components/autocomplete/CustomAutocomplete'

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

interface IGroup {
	id: string
	name: string
}

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='down' ref={ref} {...props} />
})

const ModalSwitchProfile: FC<Props> = ({ open, setOpen }) => {
	const dispatch = useDispatch()
	const { user, refresh } = useAuthContext()
	const { enqueueSnackbar } = useSnackbar()

	const [group, setGroup] = useState<IGroup | null>(null)

	const handleCloseModal = (_: {}, reason: string) => {
		if (reason === 'backdropClick') {
			return false
		}
		if (reason === 'escapeKeyDown') {
			return false
		}

		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleSetGroup = async (group: string | null) => {
		try {
			if (user?.id) {
				const resultAction = await dispatch(setGroupAccountThunk({ groupId: group, id: user.id }))
				if (setGroupAccountThunk.fulfilled.match(resultAction)) {
					enqueueSnackbar('Профиль переключен')
					refresh()
				} else {
					console.error(resultAction.error)
					enqueueSnackbar('Ошибка при переключении профиля', { variant: 'error' })
				}
				handleClose()
			}
		} catch (error) {
			console.error(error)
		}
	}

	const groupBy = (arr: any) =>
		arr.reduce((acc: { id: string }[], val: { id: string }) => {
			if (!acc.map((inx: { id: string }) => inx.id).includes(val.id)) return [...acc, val]
			return [...acc]
		}, [])

	return (
		<Dialog
			fullWidth
			maxWidth={'md'}
			TransitionComponent={Transition}
			open={open}
			disableEscapeKeyDown
			onClose={handleCloseModal}
		>
			<DialogTitle>{'Переключение профиля'}</DialogTitle>
			<IconButton sx={{ position: 'absolute', right: '18px', top: '18px' }} onClick={handleClose}>
				<Iconify icon='eva:close-fill' />
			</IconButton>
			<DialogContent sx={{ overflow: 'unset', pb: 3 }}>
				<CustomAutocomplete<any>
					value={group}
					onLoadOptions={async () => user && groupBy(user.groups)}
					onChange={(_, v) => {
						setGroup(v as IGroup)
					}}
					label='Профиль группы'
					placeholder='Выберите профиль'
					getOptionLabel={option => (typeof option === 'object' ? option.name : option) ?? ''}
					isOptionEqualToValue={(option, value) => option.id === value.id}
				/>
			</DialogContent>
			<DialogActions>
				<Stack direction={'row'} justifyContent={'end'} gap={2}>
					<LoadingButton
						size='medium'
						variant='contained'
						onClick={() => {
							if (group?.id) handleSetGroup(group.id)
						}}
					>
						Переключить
					</LoadingButton>
					<LoadingButton size='medium' variant='soft' onClick={() => handleSetGroup(null)}>
						Личный профиль
					</LoadingButton>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}

export default ModalSwitchProfile
