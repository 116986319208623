import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { dispatch as dispatchStore } from '../store'
import { IBlog } from 'src/@types/blog'

const initialState: {
	isLoading: boolean
	error: Error | string | null
	blogs: IBlog[]
} = {
	isLoading: false,
	error: null,
	blogs: [],
}

const slice = createSlice({
	name: 'videoBlock',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.blogs = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getBlogsSuccess(state, action) {
			state.isLoading = false
			const blogs = action.payload
			state.blogs = blogs
		},
	},
})

// Reducer
export default slice.reducer

export function getBlogs() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/blocked/blog`)
			dispatch(slice.actions.getBlogsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setUnBlock(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`/api/blogs/private/${id}/unblock`)
			dispatchStore(getBlogs())
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
