import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material'
// routes
import { PATH_ACCOUNT, PATH_ADMINISTRATION, PATH_AUTH, PATH_PAGE } from 'src/routes/paths'
// auth
import { useAuthContext } from 'src/auth/useAuthContext'
// components
import { CustomAvatar } from 'src/components/custom-avatar'
import { useSnackbar } from 'src/components/snackbar'
import MenuPopover from 'src/components/menu-popover'
import { IconButtonAnimate } from 'src/components/animate'
import ModalSwitchProfile from 'src/sections/switchProfile/ModalSwitchProfile'

// ----------------------------------------------------------------------

const OPTIONS = [
	{
		label: 'Настройки',
		linkTo: PATH_ACCOUNT.root,
	},
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
	const navigate = useNavigate()

	const { user, logout } = useAuthContext()
	const [openModal, setOpenModal] = useState<boolean>(false)

	const { enqueueSnackbar } = useSnackbar()

	const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

	const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
		setOpenPopover(event.currentTarget)
	}

	const handleClosePopover = () => {
		setOpenPopover(null)
	}

	const handleLogout = async () => {
		try {
			logout()
			navigate(PATH_AUTH.login, { replace: true })
			handleClosePopover()
		} catch (error) {
			console.error(error)
			enqueueSnackbar('Невозможно выйти', { variant: 'error' })
		}
	}

	const handleClickItem = (path: string) => {
		handleClosePopover()
		navigate(path)
	}

	const handleOpenModalSwitchProfile = () => {
		setOpenModal(true)
	}

	return (
		<>
			<IconButtonAnimate
				onClick={handleOpenPopover}
				sx={{
					p: 0,
					...(openPopover && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				<CustomAvatar src={user?.avatar} alt={user?.username} name={user?.username} />
			</IconButtonAnimate>

			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant='subtitle2' noWrap>
						{user?.username}
					</Typography>

					<Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
						@{user?.username}
					</Typography>
					{user?.active_group_id && (
						<Typography variant='body2' noWrap>
							{user?.active_group_id.name}
						</Typography>
					)}
				</Box>

				<MenuItem
					onClick={() => {
						handleOpenModalSwitchProfile()
						handleClosePopover()
					}}
					sx={{ ml: 1 }}
				>
					Переключить профиль
				</MenuItem>

				<Divider sx={{ borderStyle: 'dashed' }} />

				<Stack sx={{ p: 1 }}>
					<MenuItem onClick={() => handleClickItem(`${PATH_ACCOUNT.root}#payment`)}>
						Баланс: {user?.balance}
					</MenuItem>
				</Stack>

				<Divider sx={{ borderStyle: 'dashed' }} />

				<Stack sx={{ p: 1 }}>
					{OPTIONS.map(option => (
						<MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
							{option.label}
						</MenuItem>
					))}
				</Stack>
				{user?.is_staff && (
					<MenuItem onClick={() => handleClickItem(PATH_ADMINISTRATION.root)} sx={{ ml: 1 }}>
						Администрирование
					</MenuItem>
				)}
				<MenuItem onClick={() => handleClickItem(PATH_PAGE.moderation)} sx={{ ml: 1 }}>
					Модерация
				</MenuItem>
				<Divider sx={{ borderStyle: 'dashed' }} />

				<MenuItem onClick={() => handleClickItem(PATH_PAGE.support)} sx={{ ml: 1 }}>
					Обратная связь
				</MenuItem>

				<Divider sx={{ borderStyle: 'dashed' }} />

				<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
					Выйти
				</MenuItem>
			</MenuPopover>
			<ModalSwitchProfile open={openModal} setOpen={setOpenModal} />
		</>
	)
}
