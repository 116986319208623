import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IBackground, IBackgroundState } from 'src/@types/background'
import { serialize } from "object-to-formdata";
import SnackbarUtils from "../../utils/snackbar";
import { dispatch as dispatchStore } from "../store";

const initialState: IBackgroundState = {
    isLoading: false,
    background: null,
    upload: null,
    backgrounds: [],
    error: null,
}
const slice = createSlice({
    name: 'background',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.upload = null
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getBackgroundsSuccess(state, action) {
            state.isLoading = false
            const backgrounds = action.payload
            state.backgrounds = backgrounds
        },

        getBackgroundSuccess(state, action) {
            state.isLoading = false
            const background = action.payload
            state.background = background
        },
        uploadBackgroundSuccess(state, action) {
            state.isLoading = false
            const upload = action.payload
            state.upload = upload
        }
    },
})

// Reducer
export default slice.reducer

export function getBackgrounds() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get(`/api/backgrounds/list`)
            dispatch(slice.actions.getBackgroundsSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function getBackground() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get(`/api/backgrounds/select`)
            dispatch(slice.actions.getBackgroundSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function selectBackground(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.put(`/api/backgrounds/select/${id}`)
            dispatch(slice.actions.getBackgroundSuccess(response.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function deleteBackground(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.delete(`/api/backgrounds/${id}`)
            dispatchStore(getBackgrounds())
            SnackbarUtils.success('Фон удален')
        } catch (error) {
            SnackbarUtils.error('Ошибка при удалении фона')
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function updateBackground(background: IBackground) {
    return async (dispatch: Dispatch) => {
        const { id } = background
        try {
            dispatch(slice.actions.startLoading())
            const response = await axios.put(`api/backgrounds/${id}`, background)
            dispatch(slice.actions.uploadBackgroundSuccess(response.data))
            SnackbarUtils.success('Наименование фона изменено')
        } catch (err) {
            console.log(err)
            SnackbarUtils.error('Ошибка при изменении наименования фона')
            dispatch(slice.actions.hasError(err))
        }
    }
}

export function uploadBackground(file: File) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading())
            const formData = serialize({
                background: file,
                name: file?.name?.split('.').slice(0, -1).join('.'),
            })
            const response = await axios.post(`api/backgrounds/list`, formData, {
            })
            dispatch(slice.actions.uploadBackgroundSuccess(response.data))
            SnackbarUtils.success('Фон загружен')
        } catch (err) {
            console.log(err)
            SnackbarUtils.error('Ошибка при загрузке фона')
            dispatch(slice.actions.hasError(err))
        }
    }
}

