import { createSlice, Dispatch } from '@reduxjs/toolkit'
import { IDiscussion, IDiscussionState } from 'src/@types/discussion'
import { dispatch as dispatchStore } from '../store'
import axios from 'src/utils/axios'

const initialState: IDiscussionState = {
	isLoading: false,
	error: null,
	count: 0,
	comments: [],
}

const slice = createSlice({
	name: 'discussion',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getDiscussionsSuccess(state, action) {
			state.isLoading = false
			const { count, comments } = action.payload
			state.count = count
			state.comments = comments
		},
	},
})

// Reducer
export default slice.reducer

export function getDiscussions(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/video/public/item/${id}/comments`)
			dispatch(slice.actions.getDiscussionsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createDiscussion(id: string, discussion: IDiscussion) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(`/api/video/public/item/${id}/comments`, discussion)
			dispatchStore(getDiscussions(id))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function removeDisscussion(id: string, comment_id: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			let params = new URLSearchParams()
			params.append('comment_id', comment_id.toString())
			await axios.delete(`/api/video/public/item/${id}/comments`, { params })
			dispatchStore(getDiscussions(id))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getBlogDiscussions(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`/api/blogs/public/${id}/comments`)
			dispatch(slice.actions.getDiscussionsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function createBlogDiscussion(id: string, discussion: IDiscussion) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.post(`/api/blogs/public/${id}/comments`, discussion)
			dispatchStore(getBlogDiscussions(id))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
export function removeBlogDisscussion(id: string, comment_id: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			let params = new URLSearchParams()
			params.append('comment_id', comment_id.toString())
			await axios.delete(`/api/blogs/public/${id}/comments`, { params })
			dispatchStore(getBlogDiscussions(id))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}


export function setBlock(id: number) {
	return async (dispatch: Dispatch) => {
		try {
			await axios.get(`api/discussion/${id}/block`)
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
