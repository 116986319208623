import { memo } from 'react'
// @mui
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

function MailIcon({ ...other }: BoxProps) {
	return (
		<Box {...other}>
			<svg
				viewBox='0 0 60 60'
				xmlns='http://www.w3.org/2000/svg'
				focusable='false'
				width='100%'
				height='100%'
			>
				<g>
					<g fillRule='nonzero' fill='none'>
						<path
							d='M28.4863253 59.9692983c-6.6364044-.569063-11.5630204-2.3269561-16.3219736-5.8239327C4.44376366 48.4721168 3e-7 39.6467924 3e-7 29.9869344c0-14.8753747 10.506778-27.18854591 25.2744118-29.61975392 6.0281072-.9924119 12.7038532.04926445 18.2879399 2.85362966C57.1386273 10.0389054 63.3436516 25.7618627 58.2050229 40.3239688 54.677067 50.3216743 45.4153135 57.9417536 34.81395 59.5689067c-2.0856252.3201125-5.0651487.5086456-6.3276247.4003916z'
							fill='#888'
						/>
						<path
							d='M40.531502 19.160814h-22c-1.74 0-2.986 1.2375-3 3v16c0 1.7625 1.26 3 3 3h22c1.74 0 3-1.2375 3-3v-16c0-1.7625-1.26-3-3-3zm0 6l-11 7-11-7v-3l11 7 11-7v3z'
							fill='#FFF'
						/>
					</g>
				</g>
			</svg>
		</Box>
	)
}

export default memo(MailIcon)
