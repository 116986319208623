import { Theme } from '@mui/material/styles';
import { useSettingsContext } from "../../components/settings";

// ----------------------------------------------------------------------

export default function List(theme: Theme) {
  const { themeBackground } = useSettingsContext()

  return {
    MuiListItem : {
      styleOverrides: {
        root: {
          backgroundColor: themeBackground?.background ? theme.palette.background.default : theme.palette.background.default,
          borderRadius: "0.5rem"
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
}
