import { Button, Divider, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useAuthContext } from 'src/auth/useAuthContext'
import Iconify from 'src/components/iconify/Iconify'
import MenuPopover from 'src/components/menu-popover/MenuPopover'
import { useSelector } from 'src/redux/store'
import { fCommentFormat } from 'src/utils/formatNumber'
import CommentForm from './CommentForm'
import CommentList from './CommentList'

// ----------------------------------------------------------------------
const SORT_BY = [
	{ label: 'Сначала популярные', value: 'by_popular' },
	{ label: 'Сначала новые', value: 'by_new' },
]
// ----------------------------------------------------------------------
type Props = {
	Item: string
}
export default function Comments({ Item } : Props) {
	const { user } = useAuthContext()

	const { count } = useSelector(state => state.discussion)

	const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

	const [selected, setSelected] = useState<string>('by_popular')

	const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
		setOpenPopover(event.currentTarget)
	}

	const handleClosePopover = () => {
		setOpenPopover(null)
	}

	const handleClickItem = (type: string) => {
		handleClosePopover()
		setSelected(type)
	}

	return (
		<>
			<Stack>
				<Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ py: 1 }}>
					<Typography variant={'body2'} fontSize={18}>
						{fCommentFormat(count)}
					</Typography>
					<Button variant='text' onClick={handleOpenPopover}>
						<Iconify icon='material-symbols:sort' />
						<Typography variant='body2' fontWeight={600} sx={{ pl: 1 }}>
							Упорядочить
						</Typography>
					</Button>
					<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
						<Stack sx={{ p: 1 }}>
							{SORT_BY.map(option => (
								<MenuItem
									selected={selected === option.value}
									key={option.label}
									onClick={() => handleClickItem(option.value)}
								>
									{option.label}
								</MenuItem>
							))}
						</Stack>
					</MenuPopover>
				</Stack>

				{user && <CommentForm Item={ Item } />}

				<Divider sx={{ mt: 2, mb: 2 }} />
			</Stack>

			<CommentList Item={ Item }/>
		</>
	)
}
