import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IStream, IStreamState } from 'src/@types/stream'
import { dispatch as dispatchStore, store } from '../store'
import SnackbarUtils from 'src/utils/snackbar'
import moment from 'moment-timezone'

let abortController: AbortController

const initialState: IStreamState = {
	isLoading: false,
	error: null,
	streams: [],
	stream: null,
	search: '',
	count: 0,
	rowsPerPage: 10,
	pageNumber: 1,
	order: 'desc',
	orderBy: 'created_at',
	filterDateStart: null,
	filterDateEnd: null,
}

const slice = createSlice({
	name: 'stream',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.streams = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getStreamsSuccess(state, action) {
			state.isLoading = false
			const streams = action.payload
			state.streams = streams
		},

		getStreamSuccess(state, action) {
			state.isLoading = false
			const stream = action.payload
			state.stream = stream
		},
		setCount(state, action) {
			const count = action.payload
			state.count = count
		},

		setRowsPerPage(state, action) {
			const rows = action.payload
			state.rowsPerPage = rows
		},

		setPageNumber(state, action) {
			const page = action.payload
			state.pageNumber = page
		},
		setSearch(state, action) {
			const search = action.payload
			state.search = search
		},
		setFilterDateStart(state, action) {
			const filterDateStart = action.payload
			state.filterDateStart = filterDateStart
		},

		setFilterDateEnd(state, action) {
			const filterDateEnd = action.payload
			state.filterDateEnd = filterDateEnd
		},
	},
})

// Reducer
export default slice.reducer

export function getStreams() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const storage = store.getState().stream

			if (abortController) {
				abortController.abort() // Tell the browser to abort request
			}

			abortController = new AbortController()

			let params = new URLSearchParams()

			if (storage.search) {
				params.append('search', storage.search)
			}

			if (storage.filterDateStart) {
				params.append('date_start', moment(storage.filterDateStart).format('YYYY-MM-DD 00:00:00Z'))
			}

			if (storage.filterDateEnd) {
				params.append('date_end', moment(storage.filterDateEnd).format('YYYY-MM-DD 23:59:59Z'))
			}

			const response = await axios.get(`api/stream/private/list`, {
				params,
				signal: abortController && abortController.signal,
			})
			dispatch(slice.actions.getStreamsSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getStream(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/stream/private/item/${id}`)
			dispatch(slice.actions.getStreamSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export const getEmbededStreamThunk = createAsyncThunk(
	'stream/getEmbeddedStream',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await axios.get(`/api/stream/private/item/${id}`)
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const createStreamThunk = createAsyncThunk(
	'stream/createStreamThunk',
	async ({ name, description }: { name: string; description: string }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`api/stream/private/item`, { name, description })
			return response.data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export function setRowsPerPage(rows: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setRowsPerPage(rows))
		dispatchStore(setPageNumber(1))
	}
}

export function setPageNumber(page: number) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setPageNumber(page))
		dispatchStore(getStreams())
	}
}

export function setSearch(search: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setSearch(search))
		if (search.length > 2 || search.length === 0) dispatchStore(setPageNumber(1))
	}
}

export function setFilterDateStart(date: Date | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setFilterDateStart(date))
		dispatchStore(setPageNumber(1))
	}
}

// ----------------------------------------------------------------------
export function setFilterDateEnd(date: Date | null) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setFilterDateEnd(date))
		dispatchStore(setPageNumber(1))
	}
}

export function filterReset() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setFilterDateEnd(null))
		dispatch(slice.actions.setFilterDateStart(null))
		dispatch(slice.actions.setSearch(''))
		dispatchStore(setPageNumber(1))
	}
}

export function updateStream(stream: IStream) {
	return async (dispatch: Dispatch) => {
		const { id } = stream
		try {
			dispatch(slice.actions.startLoading())
			const response = await axios.put(`api/stream/private/item/${id}`, stream)
			dispatch(slice.actions.getStreamSuccess(response.data))
			SnackbarUtils.success('Информация о трансляции обновлена')
		} catch (err) {
			console.log(err)
			SnackbarUtils.error('Ошибка при обновлении трансляции')
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function startStream(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(slice.actions.startLoading())
			const response = await axios.put(`api/stream/private/item/${id}`, { is_published: true })
			dispatch(slice.actions.getStreamSuccess(response.data))
			SnackbarUtils.success('Трансляция запущена')
		} catch (err) {
			console.log(err)
			SnackbarUtils.error('Ошибка при запуске трансляции')
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function stopStream(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(slice.actions.startLoading())
			const response = await axios.put(`api/stream/private/item/${id}`, { is_published: false })
			dispatch(slice.actions.getStreamSuccess(response.data))
			SnackbarUtils.success('Трансляция остановлена')
		} catch (err) {
			console.log(err)
			SnackbarUtils.error('Ошибка при остановке трансляции')
			dispatch(slice.actions.hasError(err))
		}
	}
}

export function deleteStream(uuid: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`api/stream/private/item/${uuid}`)
			dispatchStore(setPageNumber(1))
			SnackbarUtils.success('Удалено')
		} catch (error) {
			console.error(error)
			SnackbarUtils.error(`Ошибка при удалении`)
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function deleteStreams(uuids: string[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			await axios.delete(`api/stream/private/item`, { data: uuids } )
			dispatchStore(setPageNumber(1))
			SnackbarUtils.success('Удалено')
		} catch (error) {
			console.error(error)
			SnackbarUtils.error(`Ошибка при удалении`)
			dispatch(slice.actions.hasError(error))
		}
	}
}