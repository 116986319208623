// @mui
import {
	Dialog,
	Button,
	DialogTitle,
	DialogActions,
	DialogContent,
	IconButton,
} from '@mui/material'
//
import { ConfirmDialogProps } from './types'
import Iconify from '../iconify/Iconify'

// ----------------------------------------------------------------------

export default function ConfirmDialog({
	title,
	content,
	action,
	open,
	onClose,
	closeIcon,
	...other
}: ConfirmDialogProps) {
	return (
		<Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} {...other}>
			<DialogTitle sx={{ pb: 3 }}>{title}</DialogTitle>

			{content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

			<DialogActions>
				{closeIcon && (
					<IconButton sx={{ position: 'absolute', right: '18px', top: '18px' }} onClick={onClose}>
						<Iconify icon={'material-symbols:close-rounded'} />
					</IconButton>
				)}

				{action}

				<Button variant='soft' color='primary' onClick={onClose}>
					Закрыть
				</Button>
			</DialogActions>
		</Dialog>
	)
}
