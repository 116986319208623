import React from 'react'
import { Box, SxProps } from '@mui/material'
import { IVideo } from 'src/@types/video'
import * as moment from 'moment-timezone'
import 'moment-duration-format'
import { IStream } from 'src/@types/stream'
import Iconify from 'src/components/iconify'
import { useDispatch } from 'src/redux/store'
import { setBlock as setBlockVideo } from 'src/redux/slices/video'
import { setBlock as setBlockStream } from 'src/redux/slices/broadcast'

function isVideo(s: IVideo | IStream): s is IVideo {
	return (s as IVideo).duration !== undefined
}

export default function PreviewVideo({
	video,
	height,
	width,
	sx,
}: {
	video: IVideo | IStream
	height?: number
	width?: number
	sx?: SxProps
}) {
	const { name, thumbnail } = video
	const dispatch = useDispatch()

	return (
		<Box sx={{ position: 'relative','&:hover': { transform: 'scale(1.05)' }, transition: 'transform 0.5s' }} height={'100%'}>
			<Box
				sx={{ ...sx, objectFit: 'cover'  }}
				component='img'
				src={thumbnail ? `data:image/jpeg;base64,${thumbnail}` : '/assets/background/overlay_4.jpg'}
				height={height || "100%"}
				width={width}
				alt={name}
			/>
			{video?.can_block && (
				<Box
					sx={{
						position: 'absolute',
						bottom: 30,
						right: 4,
						borderRadius: 0.5,
						backgroundColor: 'black',
						width: 24,
						height: 24,
					}}
					onClick={() =>
						dispatch(isVideo(video) ? setBlockVideo(video.id) : setBlockStream(video.id))
					}
				>
					<Iconify icon='gridicons:block' width={24} height={24} color={'white'} />
				</Box>
			)}
			{isVideo(video) && (
				<>
					{video.is_commercial && (
						<Box
							sx={{
								position: 'absolute',
								top: 4,
								right: 4,
								borderRadius: 0.5,
								backgroundColor: 'black',
								width: 24,
								height: 24,
							}}
						>
							<Iconify
								icon='majesticons:rubel-circle-line'
								width={24}
								height={24}
								color={'white'}
							/>
						</Box>
					)}
					<Box
						sx={{
							position: 'absolute',
							bottom: 4,
							right: 4,
							backgroundColor: 'black',
							borderRadius: 0.5,
							color: 'white',
							fontSize: 12,
							px: 0.5,
							py: 0.25,
						}}
					>
						{moment.duration(video.duration, 'seconds').format('hh:mm:ss', { stopTrim: 'm' })}
					</Box>
				</>
			)}
		</Box>
	)
}
