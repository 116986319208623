import { Grid, Stack, Typography } from "@mui/material";
import { useSelector } from "src/redux/store";
import { IVideo } from "src/@types/video";
import MainVideoCard from "src/components/main-video-card";
import { useParams } from "react-router";

function Playlist() {
    const { playlist } = useSelector(state => state.playlist)

    const { uuid } = useParams()

    return (
        <Stack display={"table"} sx={{ backgroundColor: theme => theme.palette.background.default }} borderRadius={2} p={2} spacing={2}>
            <Typography variant={'h5'}>Плейлист</Typography>
            <Grid
                container
                display='grid'
                gridAutoFlow='row'
                gridTemplateColumns='repeat(auto-fill, minmax(330px, 1fr))'
                gap={1.5}
                mx={2}
                sx={{ width: 'unset' }}
            >
                {playlist?.items_ids.map((video: IVideo) => (
                    <Stack key={video.id} sx={{ backgroundColor:  theme => video.id === uuid ? theme.palette.background.neutral : theme.palette.background.default }} p={2} borderRadius={2}>
                        <MainVideoCard  content={ { ...video, video_playlist_id: video.id }} />
                    </Stack>
                ))}
            </Grid>
        </Stack>
    )
}
export default Playlist