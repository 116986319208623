import React, { FC, Dispatch, forwardRef, SetStateAction, useMemo } from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Slide,
	Stack,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import Iconify from 'src/components/iconify'
import * as Yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'src/redux/store'
import { yupResolver } from '@hookform/resolvers/yup'
import FormProvider from 'src/components/hook-form/FormProvider'
import { RHFTextField } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import { createStreamThunk } from 'src/redux/slices/stream'
import { PATH_PAGE } from 'src/routes/paths'
import { useSnackbar } from 'src/components/snackbar'
import { useNavigate } from 'react-router'
import Editor from 'src/components/editor'
//---------------------------------------------------------------------------------------

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='down' ref={ref} {...props} />
})

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
}

interface FormValuesProps {
	name: string
	description: string
}

const ModalCreateStream: FC<Props> = ({ open, setOpen }) => {
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const UpdateSchema = Yup.object().shape({
		name: Yup.string().nullable().required('Заполните Наименование'),
	})

	const handleCloseModal = (_: {}, reason: string) => {
		if (reason === 'backdropClick') {
			return false
		}

		if (reason === 'escapeKeyDown') {
			return false
		}

		handleClose()
	}

	const handleClose = () => {
		setOpen(false)
		reset({ name: '', description: '' })
	}

	const onClose = (_: React.MouseEvent) => {
		handleClose()
	}

	const defaultValues = useMemo(
		() => ({
			name: '',
			description: '',
		}),
		[]
	)

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(UpdateSchema),
		defaultValues,
	})

	const {
		handleSubmit,
		reset,
		control,
		formState: { isSubmitting }, //isValid
	} = methods

	const handleCreate = async (data: FormValuesProps) => {
		const { name, description } = data
		try {
			const resultAction = await dispatch(createStreamThunk({ name, description }))
			if (createStreamThunk.fulfilled.match(resultAction)) {
				enqueueSnackbar('Трансляция создана')
				const stream = resultAction.payload
				navigate(PATH_PAGE.stream.edit(stream?.id))
			} else {
				console.error(resultAction.error)
			}
			handleClose()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Dialog
			fullWidth
			maxWidth={'md'}
			TransitionComponent={Transition}
			open={open}
			disableEscapeKeyDown
			onClose={handleCloseModal}
		>
			<DialogTitle>{'Создание трансляции'}</DialogTitle>
			<IconButton sx={{ position: 'absolute', right: '18px', top: '18px' }} onClick={onClose}>
				<Iconify icon='eva:close-fill' />
			</IconButton>
			<DialogContent sx={{ overflow: 'unset', pb: 3 }}>
				<FormProvider methods={methods}>
					<Stack direction='column' spacing={2}>
						<RHFTextField name='name' label='Наименование' />
						<Controller
							control={control}
							name='description'
							render={({ field }) => (
								<Editor
									id='stream-editor'
									value={field.value}
									simple
									onChange={value => field.onChange(value)}
									placeholder={'Введите описание'}
								/>
							)}
						/>
					</Stack>
				</FormProvider>
			</DialogContent>
			<DialogActions>
				<Stack direction={'row'} justifyContent={'end'}>
					<LoadingButton
						size='medium'
						variant='contained'
						loading={isSubmitting}
						onClick={handleSubmit(handleCreate)}
					>
						Создать
					</LoadingButton>
				</Stack>
			</DialogActions>
		</Dialog>
	)
}

export default ModalCreateStream
