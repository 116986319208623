// utils
import axios from 'src/utils/axios'

// ----------------------------------------------------------------------

export type ITokens = {
	access_token: string
	refresh_token: string
	expires: number
	expires_in: number
}

// ----------------------------------------------------------------------

export const isValidToken = (token: ITokens) => {
	if (!token) {
		return false
	}

	const currentTime = Date.now() / 1000

	return token.expires + token.expires_in > currentTime
}

// ----------------------------------------------------------------------

export const setSession = (token: ITokens | null) => {
	if (token) {
		localStorage.setItem('token', JSON.stringify(token))

		axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`
	} else {
		localStorage.removeItem('token')

		delete axios.defaults.headers.common.Authorization
	}
}
