import React, { useState, useEffect } from 'react'
// mui
import {
	Autocomplete,
	TextField,
	CircularProgress,
	createFilterOptions,
	FilterOptionsState,
	AutocompleteRenderOptionState,
	Box
} from '@mui/material'
// types
import { CustomAutocompleteProps } from './types'
//

let controller: AbortController

const CustomAutocomplete = <T extends { id: string; name?: string }>(
	props: CustomAutocompleteProps<T>
) => {
	const filter = createFilterOptions<T>()

	const {
		label,
		onLoadOptions,
		error,
		helperText,
		filterOptions,
		renderOption,
		getOptionLabel,
		openOnClick = true,
		...AutocompleteProps
	} = props

	const [open, setOpen] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<string>('')

	const [loading, setLoading] = useState<boolean>(false)

	const [options, setOptions] = useState<readonly T[]>([])

	const handleClose = () => {
		setInputValue('')
		setOpen(false)
	}

	useEffect(() => {
		let timer: NodeJS.Timeout

		if (controller) {
			controller.abort()
		}

		controller = new AbortController()

		const getResponseOptions = async () => {
			try {
				const options = await onLoadOptions(controller, inputValue)
				setOptions(options as T[])
			} catch (err) {
				setOptions([])
			}
			setLoading(false)
		}
		if (open && (openOnClick || inputValue.length > 3)) {
			setLoading(true)
			timer = setTimeout(() => {
				getResponseOptions()
			}, 500)
		}

		return () => {
			clearTimeout(timer)
			if (controller) {
				controller.abort()
			}
			setOptions([])
		}
	}, [open, openOnClick, onLoadOptions, inputValue])

	return (
		<Autocomplete
			{...AutocompleteProps}
			options={options}
			freeSolo={AutocompleteProps.freeSolo}
			multiple={AutocompleteProps.multiple}
			filterSelectedOptions={AutocompleteProps.filterSelectedOptions}
			disableCloseOnSelect={AutocompleteProps.disableCloseOnSelect}
			disableClearable={AutocompleteProps.multiple || AutocompleteProps.disableClearable}
			loadingText='Загрузка...'
			value={AutocompleteProps.value}
			inputValue={inputValue}
			onInputChange={(_, value, reason) => {
				if (AutocompleteProps.disableCloseOnSelect) {
					if (reason !== 'reset') setInputValue(value)
				} else {
					setInputValue(value)
				}
			}}
			noOptionsText='Нет доступных записей'
			open={open && (openOnClick || inputValue.length > 3)}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			onChange={AutocompleteProps.onChange}
			loading={loading}
			getOptionLabel={
				getOptionLabel
					? getOptionLabel
					: option => (typeof option === 'object' ? option.id : option)
			}
			filterOptions={(options: T[], state: FilterOptionsState<T>) =>
				filterOptions ? filterOptions(options, state, loading) : filter(options, state)
			}
			renderOption={(
				props: React.HTMLAttributes<HTMLLIElement>,
				option: T,
				state: AutocompleteRenderOptionState
			) =>
				renderOption ? (
					renderOption(props, option, state, handleClose)
				) : (
					<Box
						component='li'
						sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
						{...props}
						key={option.id}
					>
						{getOptionLabel && getOptionLabel(option)}
					</Box>
				)
			}
			renderInput={params => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						onKeyDown: e => {
							if (e.key === 'Enter') {
								e.stopPropagation()
							}
						},
						endAdornment: (
							<>
								{loading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
					error={error}
					helperText={helperText}
					variant='outlined'
					label={label}
					placeholder={AutocompleteProps.placeholder}
				/>
			)}
		/>
	)
}

export default CustomAutocomplete
