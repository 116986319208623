import { DialogContent, Stack, Typography } from '@mui/material'
import { RHFUpload } from '../hook-form'
import { useDispatch, useSelector } from 'src/redux/store'
// form
import { useForm } from 'react-hook-form'
import FormProvider from '../hook-form/FormProvider'
import { uploadVideo } from 'src/redux/slices/upload'
import { getVideos } from 'src/redux/slices/private_video'
import useActiveLink from 'src/hooks/useActiveLink'
import { PATH_PAGE } from 'src/routes/paths'
import Link from '@mui/material/Link'

interface FormValuesProps {
	file: File | null
}

export default function DialogUploadVideo() {
	const dispatch = useDispatch()

	const { active } = useActiveLink(PATH_PAGE.content.root)

	const { isUpload, loaded, total } = useSelector(state => state.upload)

	const methods = useForm<FormValuesProps>({
		defaultValues: { file: null },
	})

	const handleUploadAttachment = async (file: File) => {
		await dispatch(uploadVideo(file))
		if (active) await dispatch(getVideos())
	}

	const handleDrop = async (acceptedFiles: File[]) => {
		const file = acceptedFiles[0]

		if (file) {
			await handleUploadAttachment(file)
		}
	}

	return (
		<DialogContent sx={{ overflow: 'unset', pb: 3 }}>
			<FormProvider methods={methods}>
				<Stack
					direction='row'
					spacing={1}
					sx={{
						border: '1px solid rgba(145, 158, 171, 0.32)',
						borderRadius: 1,
					}}
				>
					<RHFUpload
						name='file'
						onDrop={handleDrop}
						accept={{ 'video/*': [] }}
						loadPreview={isUpload}
						total={total}
						loaded={loaded}
						sx={{
							flex: '1 1 auto',
							display: 'flex',
							flexDirection: 'column',

							'& > *:first-of-type': {
								flex: '1 1 auto',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							},

							'& img': {
								objectFit: 'contain',
							},
						}}
					/>
				</Stack>
			</FormProvider>
			<Stack direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{ py: 2 }}>
				<Typography variant='body2'>
					Добавля видео, вы принимаете{' '}
					<Link href='/term_of_use' underline='hover'>
						Условия использования
					</Link>{' '}
					и{' '}
					<Link href='/community_rules' underline='hover'>
						правила сообщества
					</Link>
					.
				</Typography>
				<Typography variant='body2'>
					Также вы обязуетесь соблюдать авторские права и конфиденциальность данных других
					пользователей.
				</Typography>
			</Stack>
		</DialogContent>
	)
}
