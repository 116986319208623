import React, { useEffect, useState } from 'react'
import { dispatch } from 'src/redux/store'
import { createAdvertisingThunk } from 'src/redux/slices/advertising'
import { Stack } from '@mui/material'

export default function TextTicker() {
	const [text, setText] = useState('')

	useEffect(() => {
		const s = async () => {
			const resultAction = await dispatch(createAdvertisingThunk())
			if (createAdvertisingThunk.fulfilled.match(resultAction)) {
				setText(resultAction.payload.body)
			} else {
				console.error(resultAction.error)
			}
		}
		s()
	}, [])
	return text ? <Stack>{<h1 style={{ marginTop: '1rem', marginBottom: '0.2rem' }}>{text}</h1>}</Stack> : <> &nbsp;</>
}
