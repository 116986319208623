import React, { FC } from 'react'
// mui
import { AppBar, Stack, IconButton, Tooltip, Button, Typography } from '@mui/material'
// components
import Iconify from 'src/components/iconify/Iconify'
import Logo from 'src/components/logo/Logo'
import AccountPopover from './AccountPopover'
import CreatePopover from './CreatePopover'
import SearchInput from './SearchInput'
// utils
import { useNavigate } from 'react-router-dom'
import { PATH_AUTH, PATH_PAGE } from 'src/routes/paths'
import { useAuthContext } from 'src/auth/useAuthContext'
import { useSettingsContext } from '../../components/settings'
import { alpha } from '@mui/material/styles'
import { SITE_TITLE } from 'src/config-global'

// ----------------------------------------------------------------------

interface HeaderProps {
	asideToggle: VoidFunction
}

const Header: FC<HeaderProps> = ({ asideToggle }) => {
	const { isAuthenticated } = useAuthContext()
	const { themeBackground } = useSettingsContext()

	const navigate = useNavigate()
	const handleSignInClick = () => {
		navigate(PATH_AUTH.login)
	}

	return (
		<AppBar
			sx={{
				boxShadow: 0,
				px: 2,
				height: 56,
				display: 'flex',
				alignItems: 'center',
				backgroundColor: themeBackground?.background
					? theme => alpha(theme.palette.background.paper, 0.8)
					: theme => theme.palette.background.paper,
				'& *': {
					color: theme => theme.palette.text.primary,
				},
			}}
		>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{
					width: '100%',
					height: '100%',
				}}
			>
				<Stack direction='row' alignItems='center' gap={1}>
					<IconButton size='medium' onClick={() => asideToggle()}>
						<Iconify icon='ci:hamburger-lg' />
					</IconButton>
					<Logo sx={{ alignItems: 'center' }}>
						<Typography variant='h6' letterSpacing='-1px'>
							{`${SITE_TITLE}`}
						</Typography>
					</Logo>
				</Stack>
				<Stack direction='row' alignItems='center' width='35%'>
					<SearchInput />
				</Stack>
				<Stack direction='row' alignItems='center' gap={1}>
					{isAuthenticated ? (
						<>
							<CreatePopover />
							<Tooltip title='Уведомления'>
								<IconButton size='medium'>
									<Iconify icon='ic:outline-notifications-none' />
								</IconButton>
							</Tooltip>
							<AccountPopover />
						</>
					) : (
						<>
							<Button
								sx={{ fontSize: 14, fontWeight: 600, borderRadius: 2.25, px: 2 }}
								size='medium'
								startIcon={<Iconify icon='healthicons:ui-user-profile-outline' />}
								onClick={handleSignInClick}
							>
								Войти
							</Button>
							<IconButton size='medium' onClick={() => navigate(PATH_PAGE.support)}>
								<Iconify icon='ic:twotone-rate-review' />
							</IconButton>
						</>
					)}
				</Stack>
			</Stack>
		</AppBar>
	)
}

export default Header
