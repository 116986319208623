import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IChannelState } from 'src/@types/channel'
import { store } from "../store";
import { ITag } from "src/@types/tag";

const initialState: IChannelState = {
	isLoading: false,
	error: null,
	channel: null,
	channelVideos: [],
	channelBlogs: [],
	channelStreams: [],
	channelPlaylists: [],
	tag: null,
	tags: []
}

const slice = createSlice({
	name: 'channel',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true

		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getChannelSuccess(state, action) {
			state.isLoading = false
			const channel = action.payload
			state.channel = channel
		},

		getChannelVideoSuccess(state, action) {
			state.isLoading = false
			const video = action.payload
			state.channelVideos = video
		},

		getChannelStreamSuccess(state, action) {
			state.isLoading = false
			const stream = action.payload
			state.channelStreams = stream
		},
		getChannelBlogSuccess(state, action) {
			state.isLoading = false
			const blog = action.payload
			state.channelBlogs = blog
		},
		getChannelPlaylistSuccess(state, action) {
			state.isLoading = false
			const playlist = action.payload
			state.channelPlaylists = playlist
		},
		setTag(state, action) {
			const tag = action.payload
			state.tag = tag
		},
		setTags(state, action) {
			const tags = action.payload
			state.tags = tags
		},

	},
})

// Reducer
export default slice.reducer

export function getChannel(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/channel/${id}`)
			dispatch(slice.actions.getChannelSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getChannelVideos(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const storage = store.getState().channel

			let params = new URLSearchParams()

			if (storage.tags) {
				storage.tags.map((tag: ITag) => params.append('tags', tag.id))
			}

			const response = await axios.get(`api/channel/${id}/video`, { params })
			dispatch(slice.actions.getChannelVideoSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getChannelStreams(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const storage = store.getState().channel

			let params = new URLSearchParams()

			if (storage.tags) {
				storage.tags.map((tag: ITag) => params.append('tags', tag.id))
			}

			const response = await axios.get(`api/channel/${id}/stream`, { params })
			dispatch(slice.actions.getChannelStreamSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getChannelBlogs(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const storage = store.getState().channel

			let params = new URLSearchParams()

			if (storage.tags) {
				storage.tags.map((tag: ITag) => params.append('tags', tag.id))
			}

			const response = await axios.get(`api/channel/${id}/blog`, { params })
			dispatch(slice.actions.getChannelBlogSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function getChannelPlaylists(id: string) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {

			const response = await axios.get(`api/channel/${id}/playlist`)
			dispatch(slice.actions.getChannelPlaylistSuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}

export function setChannelTags(tags: ITag[]) {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.setTags(tags))
	}
}
