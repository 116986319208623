import { createSlice, Dispatch } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { IHistoryState } from 'src/@types/video'

const initialState: IHistoryState = {
	isLoading: false,
	error: null,
	videos: [],
}

const slice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		// START LOADING
		startLoading(state) {
			state.isLoading = true
			state.videos = []
		},

		// HAS ERROR
		hasError(state, action) {
			state.isLoading = false
			state.error = action.payload
		},

		getHistorySuccess(state, action) {
			state.isLoading = false
			const videos = action.payload
			state.videos = videos
		},
	},
})

// Reducer
export default slice.reducer

export function getHistory() {
	return async (dispatch: Dispatch) => {
		dispatch(slice.actions.startLoading())
		try {
			const response = await axios.get(`api/video/public/history`)
			dispatch(slice.actions.getHistorySuccess(response.data))
		} catch (error) {
			dispatch(slice.actions.hasError(error))
		}
	}
}
